import * as React from "react";
import { DataTablePagination } from "@/components/table/data-table-pagination";
import { usePartTicket } from "../store/part-ticket.store";
import { TConfig } from "@/components/table/columns";
import { createColumns } from "@/components/table/columns";
import {
    ColumnFiltersState,
    SortingState,
    VisibilityState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from "@/components/ui/table";
import { Skeleton } from "@/components/ui/skeleton";
import { useTableLocalStore } from "@/components/table/table.store";
import { cn } from "@/lib/utils";
import { PartTicketToolbar } from "./part-ticket.toolbar";
import { filters, soldColumns } from "./part-ticket.table";

const tableConf: TConfig<any> = {
    withSelect: false,
    withActions: false,
    withFilter: true,
    cConfig: soldColumns,
};

const columns = createColumns(tableConf);

export const PartTicketSoldTable: React.FC = () => {
    const {
        loading,
        soldPartTickets,
        subscribeToSoldTickets,
        selectedPartTicket,
        setSelectedPartTicket,
        uniqueCarMakes,
        uniqueCarModels,
        uniqueCarYears,
    } = usePartTicket();
    const { setCreateDialog } = useTableLocalStore();

    React.useEffect(() => {
        const unsubscribe = subscribeToSoldTickets();
        setCreateDialog(<></>);
        return () => unsubscribe();
    }, []);

    const currentFilters = React.useMemo(() => {
        return filters.map(filter => {
            if (filter.field === 'car_make') {
                return { ...filter, options: uniqueCarMakes };
            }
            if (filter.field === 'car_model') {
                return { ...filter, options: uniqueCarModels };
            }
            if (filter.field === 'car_year') {
                return { ...filter, options: uniqueCarYears };
            }
            return filter;
        });
    }, [uniqueCarMakes, uniqueCarModels, uniqueCarYears]);

    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
    const [pagination, setPagination] = React.useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const table = useReactTable({
        data: soldPartTickets,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        state: {
            pagination,
            sorting,
            columnFilters,
            columnVisibility,
        },
        manualPagination: false,
    });

    return (
        <div className="w-full mx-auto">
            <div className="space-y-8">
                <PartTicketToolbar filters={currentFilters} table={table} />
                <div className="rounded-xl border shadow-sm overflow-hidden">
                    <Table parentClassName="h-[64vh] overflow-y-scroll">
                        <TableHeader className="sticky top-0 bg-white z-20">
                            {table.getHeaderGroups().map((headerGroup) => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <TableHead
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            className="bg-gray-50/80 font-semibold"
                                        >
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                        </TableHead>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHeader>
                        <TableBody>
                            {loading ? (
                                Array.from({ length: 5 }).map((_, index) => (
                                    <TableRow key={index}>
                                        {columns.map((_, cellIndex) => (
                                            <TableCell key={cellIndex}>
                                                <Skeleton className="h-6 w-full" />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : table.getRowModel().rows?.length ? (
                                table.getRowModel().rows.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        onClick={() => {
                                            //if (row.original.status === PartTicketStatus.IN_DESCTION) return;
                                            if (selectedPartTicket?._id === row.original._id) {
                                                setSelectedPartTicket(undefined);
                                            } else {
                                                setSelectedPartTicket(row.original);
                                            }
                                        }}
                                        data-state={selectedPartTicket?._id === row.original._id && "selected"}
                                        className={cn(
                                            "relative cursor-pointer hover:bg-gray-50 transition-colors",
                                            //row.original.status === PartTicketStatus.IN_DESCTION && "cursor-not-allowed opacity-70",
                                            selectedPartTicket?._id === row.original._id && "bg-primary/5 hover:bg-primary/10"
                                        )}
                                    >
                                        {row.getVisibleCells().map((cell) => (
                                            <TableCell key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell
                                        colSpan={columns.length}
                                        className="h-24 text-center"
                                    >
                                        No results found.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <DataTablePagination table={table} />
            </div>
        </div>
    );
};
