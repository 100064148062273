import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDH76__tUpixgFuNUYwoC8aaq8k0SulSes",
  authDomain: "scrap-2750a.firebaseapp.com",
  projectId: "scrap-2750a",
  storageBucket: "scrap-2750a.firebasestorage.app",
  messagingSenderId: "241898799185",
  appId: "1:241898799185:web:b2158f697255d693167870",
  measurementId: "G-FEPH8JMMD5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db = getFirestore(app);

export { app }

