import React, { Fragment } from "react";
import {
  Badge,
  Box,
  Checkbox,
  Chip,
  Grid,
  Icon,
  IconButton,
  ListSubheader,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import take from "lodash/take";
import cloneDeep from "lodash/cloneDeep";
import useDeepCompareEffect from "use-deep-compare-effect";
import isUndefined from "lodash/isUndefined";
import has from "lodash/has";
import { IDateFilterObj, ITableFilters } from "../modules/module.interface";
import { esQuery } from "../services/query.service";
import { toast } from "../state/snackbar";
import { AppDrawer } from "../views/Drawer";
import { AppFiltersDrawer } from "./app.filter_drawer";
import { startCase } from "lodash";
import { selectedMember$ } from "../state/user";
import { useBehaviourSubject } from "../hooks/user-obsevrable";

interface IAppFiltersChips {
  currentFilters: ITableFilters[];
  onChangeFilters: (params: ITableFilters[]) => void;
  setSelectedDateRange?: (selectedDateRange: IDateFilterObj) => void;
  dataSource: string;
  filtersCategories: {
    field_name: string;
    title: string;
    aggs_field_name: string;
    render_type?: "startCase" | "text" | "boolean";
  }[];
}

interface IBucket {
  key: string;
  doc_count: number;
}
export interface IFilter {
  field_name: string;
  data: IBucket[];
  fullData: IBucket[];
  title: string;
  render_type: string;
}

export const AppFiltersChips: React.FC<IAppFiltersChips> = ({
  onChangeFilters,
  currentFilters,
  filtersCategories,
  dataSource,
}) => {
  const theme = useTheme();
  const selectedMember = useBehaviourSubject(selectedMember$);
  const [actionsEl, setActionsEl] = React.useState<null | HTMLElement>(null);
  const [selectedChipFilter, setSelectedChipFilter] = React.useState<IFilter>();
  const [filtersLoading, setFiltersLoading] = React.useState<boolean>(false);
  const [filtersList, setFiltersList] = React.useState<IFilter[]>([]);

  const [appliedFilters, setAppliedFilters] =
    React.useState<ITableFilters[]>(currentFilters);

  const [filtersDrawer, setFiltersDrawer] = React.useState<boolean>(false);

  const fetchFiltersStats = () => {
    setFiltersLoading(true);

    const aggs_list: any = {};
    [...filtersCategories].forEach((item) => {
      aggs_list[`${item.aggs_field_name}`] = {
        terms: {
          field: item.field_name,
          size: 10000,
        },
      };
    });
    esQuery(dataSource, {
      size: 0,
      aggs: aggs_list,
    })
      .then((res) => {
        const resultedFilters: {
          field_name: string;
          data: IBucket[];
          fullData: IBucket[];
          title: string;
          render_type: string;
        }[] = [];
        const aggs_result = res.aggs;

        [...filtersCategories].forEach((item) => {
          if (
            has(aggs_result, item.aggs_field_name) &&
            has(aggs_result[`${item.aggs_field_name}`], "buckets")
          ) {
            const data_list =
              aggs_result?.[`${item.aggs_field_name}`]?.buckets || [];
            resultedFilters.push({
              field_name: item.field_name,
              title: item.title,
              fullData: data_list,
              data: take(data_list, 5),
              render_type: item?.render_type || "text",
            });
          }
        });

        setFiltersList(resultedFilters);
      })
      .catch(() => {
        toast("Error happened while fetching filters data", "error");
      })
      .finally(() => {
        setFiltersLoading(false);
      });
  };

  const publishFilters = (field_name: string, value: string, title: string) => {
    const list: ITableFilters[] = cloneDeep([...currentFilters]);
    const foundFilterIndex = list.findIndex(
      (i: any) => i.id === `${field_name}_${value}`
    );
    if (foundFilterIndex === -1) {
      list.push({
        id: `${field_name}_${value}`,
        title: title,
        operator: "is one of",
        field: field_name,
        value: value,
      });
    } else {
      list.splice(foundFilterIndex, 1);
    }

    setAppliedFilters(list);
  };

  useDeepCompareEffect(() => {
    onChangeFilters(appliedFilters);
  }, [appliedFilters]);

  useDeepCompareEffect(fetchFiltersStats, [
    dataSource,
    filtersCategories,
    selectedMember,
  ]);

  useDeepCompareEffect(() => {
    setAppliedFilters(currentFilters);
  }, [currentFilters]);

  const clearFilters = (field_name?: string) => {
    if (field_name) {
      const list: ITableFilters[] = cloneDeep([...currentFilters]);
      const x = list.filter((x) => x.field !== field_name);
      setAppliedFilters(x);
    } else {
      setAppliedFilters([]);
    }
  };

  return (
    <Fragment>
      {filtersLoading ? (
        <Stack spacing={1} direction={"row"}>
          <Skeleton
            width={"42px"}
            height={"34px"}
            variant="rounded"
            sx={{ borderRadius: "5px" }}
          />
          {filtersCategories.map((x) => (
            <Skeleton
              key={x.field_name}
              width={"99px"}
              height={"34px"}
              variant="rounded"
              sx={{ borderRadius: "5px" }}
            />
          ))}
        </Stack>
      ) : (
        <>
          <Stack spacing={1} direction={"row"}>
            <Chip
              label={
                <Box sx={{ display: "flex", alignSelf: "center" }}>
                  <Icon
                    sx={{ alignSelf: "center" }}
                    style={{ fontSize: "18px" }}
                  >
                    tune
                  </Icon>
                </Box>
              }
              variant="outlined"
              sx={{
                borderRadius: "5px",
                border: "1px solid transparent",
                height: "34px",

                backgroundColor: theme.palette.background.paper,
              }}
              onClick={() => {
                setFiltersDrawer(true);
              }}
            />
            {filtersList.map((item) => (
              <Box key={item.title}>
                <Badge
                  badgeContent={
                    currentFilters.filter((x) => x.field === item.field_name)
                      .length
                  }
                  invisible={
                    !currentFilters.some((x) => x.field === item.field_name)
                  }
                  color="secondary"
                >
                  <Chip
                    label={
                      <Stack spacing={0.5} direction={"row"}>
                        <Typography
                          sx={{
                            fontSize: 13,
                            fontWeight: 500,
                            alignSelf: "center",
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Icon
                          sx={{ alignSelf: "center" }}
                          style={{ fontSize: "18px" }}
                        >
                          expand_more
                        </Icon>
                      </Stack>
                    }
                    variant="outlined"
                    sx={{
                      borderRadius: "5px",
                      border: "1px solid transparent",
                      paddingY: "16px !important",
                      paddingLeft: "6px !important",
                      backgroundColor: theme.palette.background.paper,
                    }}
                    onClick={(e) => {
                      setActionsEl(e.currentTarget);
                      setSelectedChipFilter(item);
                    }}
                  />
                </Badge>
              </Box>
            ))}
            {currentFilters.length > 0 ? (
              <Tooltip title="Clear All Filters">
                <IconButton
                  color="error"
                  onClick={() => {
                    clearFilters();
                  }}
                >
                  <Icon style={{ fontSize: "20px" }}>filter_alt_off</Icon>
                </IconButton>
              </Tooltip>
            ) : null}
          </Stack>
          {!isUndefined(selectedChipFilter) ? (
            <Menu
              id="actions-menu"
              anchorEl={actionsEl}
              keepMounted
              open={Boolean(actionsEl)}
              onClose={() => {
                setActionsEl(null);
                setSelectedChipFilter(undefined);
              }}
              sx={{
                "& .MuiMenuItem-root": {
                  paddingY: "0px",
                  cursor: "default",
                  "&:hover": {
                    backgroundColor: theme.palette.background.paper,
                  },
                },
              }}
              slotProps={{
                paper: {
                  style: {
                    borderRadius: "10px",
                  },
                },
              }}
            >
              <ListSubheader>
                <Grid container justifyContent={"space-between"}>
                  <Grid item alignSelf={"center"}>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: theme.palette.text.primary,
                      }}
                    >
                      {selectedChipFilter.title}
                    </Typography>
                  </Grid>
                  <Grid item alignSelf={"center"}>
                    <Tooltip title="Clear Filters">
                      <IconButton
                        color="error"
                        onClick={() => {
                          clearFilters(selectedChipFilter.field_name);
                        }}
                      >
                        <Icon style={{ fontSize: "16px", marginTop: -6 }}>
                          filter_alt_off
                        </Icon>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </ListSubheader>
              <Box
                sx={{
                  maxHeight: "400px",
                  overflowY: "auto",
                }}
                className="scroll-designed"
              >
                {selectedChipFilter.fullData.map((filterItem) => (
                  <MenuItem disableTouchRipple disableGutters disableRipple>
                    <Stack
                      direction={"row"}
                      sx={{ paddingY: "0px", paddingRight: "8px" }}
                    >
                      <Checkbox
                        checked={currentFilters.some(
                          (i) =>
                            i.id ===
                            `${selectedChipFilter.field_name}_${filterItem.key}`
                        )}
                        onChange={() => {
                          publishFilters(
                            selectedChipFilter.field_name,
                            filterItem.key,
                            startCase(selectedChipFilter.field_name)
                          );
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: 14,
                          alignSelf: "center",
                          fontWeight: 500,
                        }}
                      >
                        {filterItem.key}
                      </Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </Box>
            </Menu>
          ) : null}
        </>
      )}

      {filtersDrawer ? (
        <AppDrawer
          onClose={() => {
            setFiltersDrawer(false);
          }}
          open={filtersDrawer}
          title={"Filters"}
          anchor="right"
          width={500}
        >
          <AppFiltersDrawer
            currentFilters={currentFilters}
            filtersList={filtersList}
            onChangeFilters={onChangeFilters}
          />
        </AppDrawer>
      ) : null}
    </Fragment>
  );
};
