"use client"

import { Table } from "@tanstack/react-table"
import { X } from "lucide-react"

import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { DataTableViewOptions } from "./data-table-options"
import { DataTableFacetedFilter } from "./data-table-faceted-filter"
import { TFilter } from "./table.types"
import { esQuery } from "@/services/query.service"
import { has } from "lodash"
import { useState } from "react"
import { toast } from "@/state/snackbar"
import useDeepCompareEffect from "use-deep-compare-effect"
import { useFeedPart } from "@/modules/part/part.store"


interface DataTableToolbarProps<TData> {
  table: Table<TData>
  filters: TFilter[]
}

export function DataTableToolbar<TData>({
  table,
  filters,
}: DataTableToolbarProps<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0
  const [isLoading, setIsLoading] = useState(false)
  const [filtersWithOptions, setFiltersWithOptions] = useState<TFilter[]>([...filters])
  const fetchFiltersStatsOld = () => {
    setIsLoading(true);
    const dataSource = "ds_feed_inventory_parts";
    const aggs_list: any = {};
    const filtersWithOptions: TFilter[] = [...filters];
    [...filters].forEach((item) => {
      aggs_list[`${item.field}`] = {
        terms: {
          field: item.field,
          size: 10000,
        },
      };
    });
    esQuery(dataSource, {
      size: 0,
      aggs: aggs_list,
    })
      .then((res) => {
        const aggs_result = res.aggs;
        filtersWithOptions.map((item) => {
          if (
            item.type === "select" &&
            has(aggs_result, item.field) &&
            has(aggs_result[`${item.field}`], "buckets")
          ) {
            const data_list = aggs_result?.[`${item.field}`]?.buckets || [];
            item.options = data_list.map((i: any) => {
              return { label: i.key.toUpperCase(), value: i.key }
            })
          }
        });
        setFiltersWithOptions([...filtersWithOptions])
      })
      .catch(() => {
        toast("Error happened while fetching filters data", "error");
      })
      .finally(() => {
        setIsLoading(true)
      });
  }
  const { fetchMasterParts } = useFeedPart()
  useDeepCompareEffect(() => {
    fetchFiltersStatsOld()
    fetchMasterParts()
  }, [filtersWithOptions])
  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        {filtersWithOptions && filtersWithOptions.map((filter, i) => {
          if (filter.type === "text") {
            return <Input
              key={i}
              placeholder={filter.placeholder}
              value={(table.getColumn(filter.field)?.getFilterValue() as string) ?? ""}
              onChange={(event) =>
                table.getColumn(filter.field)?.setFilterValue(event.target.value)
              }
              className="h-8 w-[150px] lg:w-[250px]"
            />
          }
          if (filter.type === "select") {
            return <DataTableFacetedFilter
              key={i}
              column={table.getColumn(filter.field)}
              title={filter.title}
              options={filter.options}
              isLoadind={isLoading}
            />
          }
        })}

        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <X />
          </Button>
        )}
      </div>
      <DataTableViewOptions table={table} />
    </div>
  )
}
