import React from "react";
import { 
  Box, 
  Button, 
  Container, 
  Grid, 
  Paper, 
  Stack, 
  Typography, 
  useTheme, 
  alpha, 
} from "@mui/material";
import Lottie from "react-lottie";
import { Warehouse, TrendingUp, Shield, Users } from "lucide-react";
import animationData from "../assets/lottie/people-producing-transparent.json";
import logo from "../assets/logo/logo.png";
import "./LandingPage.css";

export const LandingPage: React.FC = () => {
  const theme = useTheme();

  const primaryRed = theme.palette.error.main;
  const lightRed = alpha(theme.palette.error.main, 0.1);
  const darkRed = theme.palette.error.dark;

  const features = [
    {
      icon: <Warehouse size={48} color={primaryRed} />,
      title: "Inventory Management",
      description: "Effortlessly track and manage your parts inventory with our streamlined system."
    },
    {
      icon: <TrendingUp size={48} color={primaryRed} />,
      title: "Business Growth",
      description: "Connect with more customers and expand your market reach through our platform."
    },
    {
      icon: <Shield size={48} color={primaryRed} />,
      title: "Reliable Security",
      description: "Your data is secure with our enterprise-grade security features."
    },
    {
      icon: <Users size={48} color={primaryRed} />,
      title: "Customer Connection",
      description: "Directly engage with customers looking for specific auto parts."
    }
  ];

  const testimonials = [
    {
      text: "Before Scrap, we were using paper records and spreadsheets. Now I can check our inventory instantly and know exactly what parts we have in stock. It's saved us hours of work every week.",
      name: "Ahmed Al Khaldi",
      role: "Manager, Al Khaldi Auto Parts"
    },
    {
      text: "The search feature helps us find parts in seconds instead of digging through the yard. When customers call asking for specific parts, we can tell them right away if we have it.",
      name: "Sarah Johnson",
      role: "Parts Specialist, Johnson Auto"
    },
    {
      text: "We're getting more business from repair shops since joining. They can see our inventory online and know exactly what we have. Makes everyone's job easier.",
      name: "Mohammed Al-Farsi",
      role: "Owner, Gulf Auto Parts"
    }
  ];

  return (
    <Box className="landing-page">
      {/* Hero Section */}
      <Box 
        sx={{ 
          background: `linear-gradient(135deg, ${theme.palette.background.default} 0%, ${alpha(primaryRed, 0.05)} 100%)`,
          minHeight: "90vh",
          pt: 8,
          pb: 8
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Stack spacing={4}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img src={logo} alt="Scrap Logo" width={48} height={48} />
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 700,
                      letterSpacing: "1.5px",
                      ml: 1
                    }}
                  >
                    <span style={{ color: primaryRed }}>S</span>crap
                  </Typography>
                </Box>
                
                <Typography variant="h2" component="h1" sx={{ fontWeight: 800, letterSpacing: "0.5px" }}>
                  Revolutionize Your Scrap Business
                </Typography>
                
                <Typography variant="h5" color="text.secondary" sx={{ fontWeight: 400 }}>
                  Connect with customers, manage inventory, and grow your auto parts business with our all-in-one platform.
                </Typography>
                
                <Box sx={{ mt: 2 }}>
                  <Button 
                    variant="contained" 
                    size="large"
                    href="/auth/register"
                    sx={{
                      py: 1.5,
                      px: 4,
                      fontSize: "1.2rem",
                      borderRadius: "8px",
                      backgroundColor: primaryRed,
                      color: "white",
                      '&:hover': {
                        backgroundColor: darkRed,
                      }
                    }}
                  >
                    Get Started
                  </Button>
                  <Button 
                    variant="outlined" 
                    size="large"
                    href="/auth/login"
                    sx={{
                      py: 1.5,
                      px: 4,
                      fontSize: "1.2rem",
                      borderRadius: "8px",
                      ml: 2,
                      borderColor: primaryRed,
                      color: primaryRed,
                      '&:hover': {
                        borderColor: darkRed,
                        backgroundColor: lightRed,
                      }
                    }}
                  >
                    Sign In
                  </Button>
                </Box>
              </Stack>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Lottie
                options={{
                  animationData: animationData,
                  loop: true,
                  autoplay: true,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                width="100%"
                height={400}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Features Section */}
      <Box sx={{ py: 10, backgroundColor: theme.palette.background.paper }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            component="h2" 
            align="center"
            sx={{ 
              mb: 6, 
              fontWeight: 700,
              '& span': { 
                color: primaryRed
              }
            }}
          >
            Why <span>Scrap Businesses</span> Choose Us
          </Typography>
          
          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Paper 
                  elevation={2} 
                  sx={{ 
                    p: 4, 
                    height: '100%',
                    transition: 'transform 0.3s',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                      boxShadow: '0 10px 20px rgba(0,0,0,0.1)'
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderRadius: '12px'
                  }}
                >
                  <Box 
                    sx={{ 
                      color: primaryRed, 
                      mb: 2,
                      p: 2,
                      borderRadius: '50%',
                      backgroundColor: lightRed,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    {feature.icon}
                  </Box>
                  <Typography variant="h6" component="h3" align="center" sx={{ mb: 2, fontWeight: 600 }}>
                    {feature.title}
                  </Typography>
                  <Typography align="center" color="text.secondary">
                    {feature.description}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* How It Works Section */}
      <Box sx={{ py: 10, backgroundColor: theme.palette.background.default }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            component="h2" 
            align="center"
            sx={{ 
              mb: 6, 
              fontWeight: 700 
            }}
          >
            How It Works
          </Typography>
          
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative',
                  overflow: 'hidden',
                  borderRadius: '12px',
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                  },
                  height: 'auto',
                  my: 2,
                  mt: { xs: 2, md: -6 }
                }}
              >
                <img 
                  src="/images/dashboard/scrap-dashboard.png" 
                  alt="Scrap Dashboard" 
                  style={{ 
                    width: '100%',
                    maxWidth: '550px',
                    height: 'auto',
                    objectFit: 'cover',
                    objectPosition: 'center top',
                    borderRadius: '12px',
                    boxShadow: '0 15px 40px rgba(0,0,0,0.15), 0 5px 20px rgba(0,0,0,0.1), 0 0 5px rgba(0,0,0,0.05)',
                    border: `1px solid ${alpha(theme.palette.divider, 0.2)}`
                  }} 
                />
              </Box>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Stack spacing={4}>
                <Box>
                  <Typography 
                    variant="h2" 
                    component="span"
                    sx={{ 
                      color: primaryRed,
                      fontWeight: 700,
                      mr: 2
                    }}
                  >
                    1.
                  </Typography>
                  <Typography variant="h5" component="h3" sx={{ fontWeight: 600, display: 'inline' }}>
                    Register your business
                  </Typography>
                  <Typography sx={{ mt: 1 }}>
                    Create your account with basic business information and contact details.
                  </Typography>
                </Box>
                
                <Box>
                  <Typography 
                    variant="h2" 
                    component="span"
                    sx={{ 
                      color: primaryRed,
                      fontWeight: 700,
                      mr: 2
                    }}
                  >
                    2.
                  </Typography>
                  <Typography variant="h5" component="h3" sx={{ fontWeight: 600, display: 'inline' }}>
                    Set up your inventory
                  </Typography>
                  <Typography sx={{ mt: 1 }}>
                    Add your parts inventory with detailed descriptions and pricing.
                  </Typography>
                </Box>
                
                <Box>
                  <Typography 
                    variant="h2" 
                    component="span"
                    sx={{ 
                      color: primaryRed,
                      fontWeight: 700,
                      mr: 2
                    }}
                  >
                    3.
                  </Typography>
                  <Typography variant="h5" component="h3" sx={{ fontWeight: 600, display: 'inline' }}>
                    Connect with customers
                  </Typography>
                  <Typography sx={{ mt: 1 }}>
                    Start receiving part requests and engage with customers directly.
                  </Typography>
                </Box>
                
                <Button 
                  variant="contained" 
                  size="large"
                  href="/auth/register"
                  sx={{
                    py: 1.5,
                    px: 4,
                    fontSize: "1.1rem",
                    borderRadius: "8px",
                    alignSelf: "flex-start",
                    mt: 2,
                    backgroundColor: primaryRed,
                    color: "white",
                    '&:hover': {
                      backgroundColor: darkRed,
                    }
                  }}
                >
                  Get Started Now
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Testimonials Section */}
      <Box sx={{ py: 10, backgroundColor: theme.palette.background.paper }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            component="h2" 
            align="center"
            sx={{ 
              mb: 6, 
              fontWeight: 700 
            }}
          >
            What Scrap Businesses Say
          </Typography>
          
          <Grid container spacing={4}>
            {testimonials.map((testimonial, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 4,
                    borderRadius: '12px',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Typography variant="body1" sx={{ mb: 3, fontStyle: 'italic' }}>
                    "{testimonial.text}"
                  </Typography>
                  
                  <Box sx={{ mt: 'auto', display: 'flex', alignItems: 'center' }}>
                    <Box
                      sx={{
                        width: 50,
                        height: 50,
                        borderRadius: '50%',
                        backgroundColor: primaryRed,
                        mr: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        fontWeight: 'bold'
                      }}
                    >
                      {String.fromCharCode(65 + index)}
                    </Box>
                    <Box>
                      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                        {testimonial.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {testimonial.role}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* CTA Section */}
      <Box 
        sx={{ 
          py: 10, 
          background: `
            linear-gradient(135deg, rgba(140, 20, 30, 0.9) 0%, rgba(220, 60, 60, 0.85) 100%)
          `,
          position: 'relative',
          color: "white",
          overflow: 'hidden',
          '&>.dots-pattern': {
            position: 'absolute',
            top: '20%',
            left: '15%',
            width: '70%',
            height: '60%',
            background: `url("data:image/svg+xml,%3Csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='2' cy='2' r='1' fill='%23FFFFFF' fill-opacity='0.1'/%3E%3C/svg%3E")`,
            zIndex: 0,
          },
          '&>.diagonal-lines': {
            position: 'absolute',
            top: 0,
            right: 0,
            width: '100%',
            height: '100%',
            background: `linear-gradient(45deg, transparent 49.5%, rgba(255,255,255,0.05) 49.5%, rgba(255,255,255,0.05) 50.5%, transparent 50.5%)`,
            backgroundSize: '25px 25px',
            zIndex: 0,
          },
          '& > *:not(.dots-pattern):not(.diagonal-lines)': {
            position: 'relative',
            zIndex: 1
          }
        }}
      >
        <Box className="dots-pattern"></Box>
        <Box className="diagonal-lines"></Box>
        
        <Container maxWidth="md">
          <Stack spacing={4} alignItems="center" textAlign="center">
            <Typography variant="h3" component="h2" sx={{ fontWeight: 700 }}>
              Ready to Transform Your Scrap Business?
            </Typography>
            
            <Typography variant="h6" sx={{ maxWidth: '800px', mx: 'auto' }}>
              Join hundreds of scrap businesses already using our platform to connect with customers and grow their operations.
            </Typography>
            
            <Box>
              <Button 
                variant="contained" 
                size="large"
                href="/auth/register"
                sx={{
                  py: 2,
                  px: 6,
                  fontSize: "1.2rem",
                  borderRadius: "8px",
                  backgroundColor: 'white',
                  color: primaryRed,
                  position: 'relative',
                  overflow: 'hidden',
                  transition: 'all 0.3s ease',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: '-100%',
                    width: '100%',
                    height: '100%',
                    background: `linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent)`,
                    transition: 'left 0.7s ease',
                  },
                  '&:hover': {
                    backgroundColor: theme.palette.background.paper,
                    transform: 'translateY(-3px)',
                    boxShadow: '0 6px 20px rgba(0,0,0,0.15)',
                    '&::before': {
                      left: '100%',
                    }
                  },
                  '&:active': {
                    transform: 'translateY(1px)',
                    boxShadow: '0 2px 10px rgba(0,0,0,0.15)',
                  }
                }}
              >
                Register Your Business Today
              </Button>
              
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ color: 'rgba(255,255,255,0.9)' }}>
                  Already registered?
                </Typography>
                <Button 
                  href="/auth/login" 
                  sx={{ 
                    color: 'white', 
                    fontWeight: 'bold', 
                    ml: 1,
                    position: 'relative',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: 0,
                      left: '50%',
                      width: '0%',
                      height: '2px',
                      backgroundColor: 'white',
                      transition: 'all 0.3s ease',
                      transform: 'translateX(-50%)',
                    },
                    '&:hover': {
                      backgroundColor: 'transparent',
                      '&::after': {
                        width: '80%',
                      }
                    }
                  }}
                >
                  Sign in to your account
                </Button>
              </Box>
            </Box>
          </Stack>
        </Container>
      </Box>

      {/* Footer */}
      <Box 
        sx={{ 
          py: 5, 
          backgroundColor: theme.palette.background.paper,
          borderTop: `1px solid ${theme.palette.divider}`
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="flex-start">
            <Grid item xs={12} md={5}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <img src={logo} alt="Scrap Logo" width={42} height={42} />
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                    letterSpacing: "1.5px",
                    ml: 1
                  }}
                >
                  <span style={{ color: primaryRed }}>S</span>crap
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2, maxWidth: "380px" }}>
                Connecting scrap businesses with customers looking for auto parts. Our platform helps you manage inventory, grow your business, and provide better service.
              </Typography>
              <Typography variant="body2" color="text.secondary">
                © {new Date().getFullYear()} VENESTUSY W.L.L. All rights reserved.
              </Typography>
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
                Quick Links
              </Typography>
              <Stack spacing={-0.1}>
                <Button 
                  href="/" 
                  sx={{ 
                    textDecoration: 'none', 
                    color: theme.palette.text.secondary,
                    textTransform: 'none',
                    justifyContent: 'flex-start',
                    padding: '4px 0',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      color: primaryRed,
                    }
                  }}
                >
                  Home
                </Button>
                <Button 
                  href="https://venustusy.com/" 
                  sx={{ 
                    textDecoration: 'none', 
                    color: theme.palette.text.secondary,
                    textTransform: 'none',
                    justifyContent: 'flex-start',
                    padding: '4px 0',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      color: primaryRed,
                    }
                  }}
                >
                  About Us
                </Button>
                <Button 
                  href="https://venustusy.com/contact" 
                  sx={{ 
                    textDecoration: 'none', 
                    color: theme.palette.text.secondary,
                    textTransform: 'none',
                    justifyContent: 'flex-start',
                    padding: '4px 0',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      color: primaryRed,
                    }
                  }}
                >
                  Contact Us
                </Button>
              </Stack>
            </Grid>
            
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
                Connect With Us
              </Typography>
              <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <Box
                  component="a"
                  href="https://twitter.com/VENUSTUSY115710"
                  target="_blank"
                  sx={{
                    borderRadius: "50%",
                    backgroundColor: alpha(theme.palette.error.main, 0.1),
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: primaryRed,
                    transition: "all 0.2s ease",
                    '&:hover': {
                      backgroundColor: primaryRed,
                      color: 'white',
                      transform: 'translateY(-3px)'
                    }
                  }}
                >
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
                  </svg>
                </Box>
                
                <Box
                  component="a"
                  href="https://facebook.com"
                  target="_blank"
                  sx={{
                    borderRadius: "50%",
                    backgroundColor: alpha(theme.palette.error.main, 0.1),
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: primaryRed,
                    transition: "all 0.2s ease",
                    '&:hover': {
                      backgroundColor: primaryRed,
                      color: 'white',
                      transform: 'translateY(-3px)'
                    }
                  }}
                >
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M9.198 21.5h4v-8.01h3.604l.396-3.98h-4V7.5a1 1 0 0 1 1-1h3v-4h-3a5 5 0 0 0-5 5v2.01h-2l-.396 3.98h2.396v8.01Z" />
                  </svg>
                </Box>
                
                <Box
                  component="a"
                  href="https://instagram.com"
                  target="_blank"
                  sx={{
                    borderRadius: "50%",
                    backgroundColor: alpha(theme.palette.error.main, 0.1),
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: primaryRed,
                    transition: "all 0.2s ease",
                    '&:hover': {
                      backgroundColor: primaryRed,
                      color: 'white',
                      transform: 'translateY(-3px)'
                    }
                  }}
                >
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 1.802c-2.67 0-2.986.01-4.04.059-.976.045-1.505.207-1.858.344-.466.182-.8.398-1.15.748-.35.35-.566.684-.748 1.15-.137.353-.3.882-.344 1.857-.048 1.055-.058 1.37-.058 4.041 0 2.67.01 2.986.058 4.04.045.977.207 1.505.344 1.858.182.466.399.8 1.15.748.35.35.684.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058 2.67 0 2.987-.01 4.04-.058.977-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.684.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041 0-2.67-.01-2.986-.058-4.04-.045-.977-.207-1.505-.344-1.858a3.097 3.097 0 0 0-.748-1.15 3.098 3.098 0 0 0-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.055-.048-1.37-.058-4.041-.058zm0 3.063a5.135 5.135 0 1 1 0 10.27 5.135 5.135 0 0 1 0-10.27zm0 8.468a3.333 3.333 0 1 0 0-6.666 3.333 3.333 0 0 0 0 6.666zm6.538-8.469a1.2 1.2 0 1 1-2.4 0 1.2 1.2 0 0 1 2.4 0z" />
                  </svg>
                </Box>
              </Stack>
              
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Contact us: <Box component="span" sx={{ fontWeight: 500 }}>info@venustusy.com</Box>
              </Typography>
            </Grid>
          </Grid>
          
          <Box sx={{ 
            mt: 4, 
            pt: 3, 
            borderTop: `1px solid ${alpha(theme.palette.divider, 0.5)}`,
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: 2
          }}>
            <Typography variant="body2" color="text.secondary">
              Scrap - Making auto part sourcing easier
            </Typography>
            <Stack direction="row" spacing={3}>
              <Button 
                href="/terms" 
                sx={{ 
                  color: theme.palette.text.secondary,
                  textTransform: 'none',
                  fontSize: '0.875rem',
                  padding: 0,
                  minWidth: 'auto',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: primaryRed,
                  }
                }}
              >
                Terms
              </Button>
              <Button 
                href="/privacy" 
                sx={{ 
                  color: theme.palette.text.secondary,
                  textTransform: 'none',
                  fontSize: '0.875rem',
                  padding: 0,
                  minWidth: 'auto',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: primaryRed,
                  }
                }}
              >
                Privacy
              </Button>
            </Stack>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default LandingPage; 