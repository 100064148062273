import { Table } from "@tanstack/react-table"
import { X } from "lucide-react"

import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { DataTableViewOptions } from "@/components/table/data-table-options"
import { DataTableFacetedFilter } from "@/components/table/data-table-faceted-filter"
import { TFilter } from "@/components/table/table.types"
import { usePartTicket } from "../store/part-ticket.store"
import { useMemo } from "react"
import { useState } from "react"

interface DataTableToolbarProps<TData> {
  table: Table<TData>
  filters: TFilter[]
}

export function PartTicketToolbar<TData>({
  table,
  filters,
}: DataTableToolbarProps<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0
  const [isLoading, setIsLoading] = useState(false)
  const {
    uniqueCarMakes,
    uniqueCarModels,
    uniqueCarYears,
  } = usePartTicket()

  // Memoize the filters with their options to prevent unnecessary recalculations
  const filtersWithOptions = useMemo(() => {
    return filters.map(filter => {
      switch (filter.field) {
        case 'car_make':
          return { ...filter, options: uniqueCarMakes };
        case 'car_model':
          return { ...filter, options: uniqueCarModels };
        case 'car_year':
          return { ...filter, options: uniqueCarYears };
        default:
          return filter;
      }
    });
  }, [filters, uniqueCarMakes, uniqueCarModels, uniqueCarYears]);

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        {filtersWithOptions.map((filter, i) => {
          if (filter.type === "text") {
            return <Input
              key={i}
              placeholder={filter.placeholder}
              value={(table.getColumn(filter.field)?.getFilterValue() as string) ?? ""}
              onChange={(event) =>
                table.getColumn(filter.field)?.setFilterValue(event.target.value)
              }
              className="h-8 w-[150px] lg:w-[250px]"
            />
          }
          if (filter.type === "select") {
            return <DataTableFacetedFilter
              key={i}
              column={table.getColumn(filter.field)}
              title={filter.title}
              options={filter.options}
              isLoadind={isLoading}
            />
          }
        })}

        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <X />
          </Button>
        )}
      </div>
      <DataTableViewOptions table={table} />
    </div>
  )
}
