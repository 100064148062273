import * as React from "react";
import { TFilter } from "@/components/table/table.types";
import { TConfig } from "@/components/table/columns";
import { createColumns } from "@/components/table/columns";
import { DataTablePagination } from "@/components/table/data-table-pagination";
import { PartTicketStatus, PartTickit, usePartTicket, PartTickitPriceSuggestion } from "../store/part-ticket.store";
import {
    ColumnFiltersState,
    SortingState,
    VisibilityState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from "@/components/ui/table";
import { Skeleton } from "@/components/ui/skeleton";
import { useTableLocalStore } from "@/components/table/table.store";
import { cn } from "@/lib/utils";
import { Badge } from "@/components/ui/badge";
import { ImageOffIcon } from "lucide-react";
import { PartTicketToolbar } from "./part-ticket.toolbar";


export const filters: TFilter[] = [
    {
        type: "select",
        field: "car_make",
        title: "Car Make",
        options: [],
    },
    {
        type: "select",
        field: "car_model",
        title: "Car Model",
        options: [],
    },
    {
        type: "select",
        field: "car_year",
        title: "Car Year",
        options: [],
    },
    {
        type: "select",
        field: "status",
        title: "Status",
        options: Object.values(PartTicketStatus).map(status => ({
            label: status.charAt(0).toUpperCase() + status.slice(1),
            value: status
        })),
    },
];

// Base columns that are common to all tables
export const baseColumns: TConfig<PartTickit>['cConfig'] = [
    {
        accessorKey: "images",
        header: "Image",
        cellType: "custom",
        columnDefProps: {
            enableSorting: false,
            header: () => (
                <div className="text-center text-xs font-medium">
                    Image
                </div>
            ),
            cell: ({ row }) => {
                const images = row.getValue<PartTickit['images']>("images");
                const validImage = images?.find(img => img.expiry_date > Date.now());

                return (
                    <div className="w-full h-[75px] flex justify-center items-center rounded-lg overflow-hidden bg-gray-50">
                        {validImage ? (
                            <img
                                src={validImage.image_url}
                                alt="Part Image"
                                className="h-full w-full object-cover hover:scale-105 transition-transform duration-200"
                            />
                        ) : (
                            <div className="flex flex-col items-center gap-1 text-gray-400">
                                <ImageOffIcon className="w-6 h-6" />
                                <span className="text-xs">No image</span>
                            </div>
                        )}
                    </div>
                );
            },
        },
    },
    {
        accessorKey: "car_make",
        header: "Car make",
        cellType: "text",
    },
    {
        accessorKey: "car_model",
        header: "Car model",
        cellType: "text",
    },
    {
        accessorKey: "car_year",
        header: "Car year",
        cellType: "text",
    },
    {
        accessorKey: "part_name",
        header: "Part",
        cellType: "custom",
        columnDefProps: {
            cell: ({ row }) => (
                <div className="font-medium text-gray-700">{row.getValue("part_name")}</div>
            ),
        },
    },
    {
        accessorKey: "description",
        header: "Description",
        cellType: "custom",
        columnDefProps: {
            cell: ({ row }) => {
                const description = row.getValue<string>("description");
                return (
                    <div className="max-w-[300px] truncate text-gray-600">
                        {description || "No description"}
                    </div>
                );
            },
        },
    },
    {
        accessorKey: "status",
        header: "Status",
        cellType: "custom",
        columnDefProps: {
            cell: ({ row }) => {
                const status = row.getValue<string>("status");
                return (
                    <Badge
                        variant="outline"
                        className={cn(
                            "capitalize",
                            status === PartTicketStatus.OPEN && "bg-green-50 text-green-700 border-green-300",
                            status === PartTicketStatus.CLOSE && "bg-red-50 text-red-700 border-red-300",
                            status === PartTicketStatus.IN_DESCTION && "bg-orange-50 text-orange-700 border-orange-300"
                        )}
                    >
                        {status.replace("_", " ")}
                    </Badge>
                );
            },
        },
    },
];

// Available table columns (adds created_at)
export const availableColumns: TConfig<PartTickit>['cConfig'] = [
    ...baseColumns,
    {
        accessorKey: "created_at",
        header: "Created At",
        cellType: "custom",
        columnDefProps: {
            cell: ({ row }) => {
                const created_at = row.getValue<number>("created_at");
                return (
                    <div className="text-gray-600">
                        {created_at ? new Date(created_at).toLocaleString() : "N/A"}
                    </div>
                );
            },
        },
    },
];

// Pending table columns (adds last suggested price)
export const pendingColumns: TConfig<PartTickit>['cConfig'] = [
    ...baseColumns,
    {
        accessorKey: "suggested_prices",
        header: "Last Price",
        cellType: "custom",
        columnDefProps: {
            cell: ({ row }) => {
                const suggested_prices = row.getValue<PartTickitPriceSuggestion[]>("suggested_prices");
                if (!suggested_prices?.length) return <div className="text-gray-600">No offers</div>;

                // Get the most recent suggestion
                const lastSuggestion = [...suggested_prices].sort((a, b) => b.created_at - a.created_at)[0];

                return (
                    <div className="text-gray-600">
                        {lastSuggestion.price.toFixed(2)} BD
                    </div>
                );
            },
        },
    },
];

// Sold table columns (adds sold price)
export const soldColumns: TConfig<PartTickit>['cConfig'] = [
    ...baseColumns,
    {
        accessorKey: "suggested_prices",
        header: "Sold Price",
        cellType: "custom",
        columnDefProps: {
            cell: ({ row }) => {
                const ticket = row.original;
                const suggested_prices = ticket.suggested_prices;
                if (!suggested_prices?.length || !ticket.scrap_id) return <div className="text-gray-600">N/A</div>;

                // Find the suggestion that matches the scrap_id
                const soldSuggestion = suggested_prices.find(s => s.scrap_id === ticket.scrap_id);

                return (
                    <div className="text-gray-600">
                        {soldSuggestion ? `${soldSuggestion.price.toFixed(2)} BD` : "N/A"}
                    </div>
                );
            },
        },
    },
];

// Default table configuration (using base columns)
const tableConf: TConfig<PartTickit> = {
    withSelect: false,
    withActions: false,
    withFilter: true,
    cConfig: baseColumns,
};

export const columns = createColumns(tableConf);


export const PartTicketTable: React.FC = () => {
    const {
        loading,
        data,
        subscribeToUpdates,
        selectedPartTicket,
        setSelectedPartTicket,
        uniqueCarMakes,
        uniqueCarModels,
        uniqueCarYears,
        fetchUniqueValues,
    } = usePartTicket();
    const { setCreateDialog } = useTableLocalStore();

    React.useEffect(() => {
        const unsubscribe = subscribeToUpdates();
        setCreateDialog(<></>);
        fetchUniqueValues(); // Fetch unique values when component mounts
        return () => unsubscribe();
    }, []);

    const currentFilters = React.useMemo(() => {
        return filters.map(filter => {
            if (filter.field === 'car_make') {
                return { ...filter, options: uniqueCarMakes };
            }
            if (filter.field === 'car_model') {
                return { ...filter, options: uniqueCarModels };
            }
            if (filter.field === 'car_year') {
                return { ...filter, options: uniqueCarYears };
            }
            return filter;
        });
    }, [uniqueCarMakes, uniqueCarModels, uniqueCarYears]);

    const tableConf: TConfig<PartTickit> = {
        withSelect: false,
        withActions: false,
        withFilter: true,
        cConfig: baseColumns,
    };

    const columns = createColumns(tableConf);

    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
    const [pagination, setPagination] = React.useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const table = useReactTable({
        data: data || [],
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        state: {
            pagination,
            sorting,
            columnFilters,
            columnVisibility,
        },
        manualPagination: false,
    });

    return (
        <div className="w-full mx-auto">
            <div className="space-y-8">
                <PartTicketToolbar filters={currentFilters} table={table} />
                <div className="rounded-xl border shadow-sm overflow-hidden">
                    <Table parentClassName="h-[64vh] overflow-y-scroll">
                        <TableHeader className="sticky top-0 bg-white z-20">
                            {table.getHeaderGroups().map((headerGroup) => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <TableHead
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            className="bg-gray-50/80 font-semibold"
                                        >
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                        </TableHead>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHeader>
                        <TableBody>
                            {loading ? (
                                Array.from({ length: 5 }).map((_, index) => (
                                    <TableRow key={index}>
                                        {columns.map((_, cellIndex) => (
                                            <TableCell key={cellIndex}>
                                                <Skeleton className="h-6 w-full" />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : table.getRowModel().rows?.length ? (
                                table.getRowModel().rows.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        onClick={() => {
                                            //if (row.original.status === PartTicketStatus.IN_DESCTION) return;
                                            if (selectedPartTicket?._id === row.original._id) {
                                                setSelectedPartTicket(undefined);
                                            } else {
                                                setSelectedPartTicket(row.original);
                                            }
                                        }}
                                        data-state={selectedPartTicket?._id === row.original._id && "selected"}
                                        className={cn(
                                            "relative cursor-pointer hover:bg-gray-50 transition-colors",
                                            //row.original.status === PartTicketStatus.IN_DESCTION && "cursor-not-allowed opacity-70",
                                            selectedPartTicket?._id === row.original._id && "bg-primary/5 hover:bg-primary/10"
                                        )}
                                    >
                                        {row.getVisibleCells().map((cell) => (
                                            <TableCell key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell
                                        colSpan={columns.length}
                                        className="h-24 text-center"
                                    >
                                        No results found.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <DataTablePagination table={table} />
            </div>
        </div>
    );
};
