import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { IMemberCreateRequest, IMemberRequest } from '../../modules/module.interface'
import { CreateRequestRecord } from '../../services/request.service'
import { FetchLoginCredentials } from '../../services/auth.service'

interface AuthState {
  user: any | null
  isLoading: boolean
  isAuthenticated: boolean
  login: (email: string, password: string, keepLoggedIn: boolean) => Promise<void>
  register: (data: IMemberCreateRequest) => Promise<void>
  logout: () => void
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      user: null,
      isLoading: false,
      isAuthenticated: false,

      login: async (email: string, password: string, keepLoggedIn: boolean) => {
        set({ isLoading: true })
        try {
          const res = await FetchLoginCredentials(email, password)
          if (res.data.length === 1) {
            const userData = res.data[0]
            sessionStorage.setItem('logged_user_record', JSON.stringify(userData))
            if (keepLoggedIn) {
              localStorage.setItem('logged_user_record', JSON.stringify(userData))
            }
            set({ user: userData, isAuthenticated: true })
            window.location.replace('/')
          } else if (res.data.length > 1) {
            throw new Error('Duplicate user found')
          } else {
            throw new Error('Invalid credentials')
          }
        } finally {
          set({ isLoading: false })
        }
      },

      register: async (data: IMemberCreateRequest) => {
        set({ isLoading: true })
        try {
          const requestRecord: IMemberRequest = {
            action: 'Create',
            module: 'member',
            value: data,
            created_by: data.email,
          }
          await CreateRequestRecord(requestRecord)
        } finally {
          set({ isLoading: false })
        }
      },

      logout: () => {
        localStorage.clear()
        sessionStorage.clear()
        set({ user: null, isAuthenticated: false })
        window.location.reload()
      },
    }),
    {
      name: 'auth-storage',
    }
  )
) 