import React from "react";
import { useAuthStore } from "./store/auth.store";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { toast } from "sonner";
import { ValidateEmail } from "../util/validator";
import { Mail, Lock } from "lucide-react";

interface ILoginForm {
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoginForm: React.FC<ILoginForm> = ({ setIsLogin }) => {
  const [userEmail, setUserEmail] = React.useState<string>("");
  const [userPassword, setUserPassword] = React.useState<string>("");
  const [keepLoggedIn, setKeepLoggedIn] = React.useState<boolean>(true);

  const { login, isLoading } = useAuthStore();

  const handleLogin = async () => {
    try {
      await login(userEmail, userPassword, keepLoggedIn);
    } catch (error: any) {
      toast.error("Error", {
        description: error.message || "An error occurred during login"
      });
      setUserEmail("");
      setUserPassword("");
      setKeepLoggedIn(false);
    }
  };

  const handleKeyDown = (ev: React.KeyboardEvent) => {
    if (ev.key === "Enter" && userEmail && userPassword) {
      handleLogin();
    }
  };

  React.useEffect(() => {
    const user = useAuthStore.getState().user;
    if (user) {
      window.location.replace("/");
    }
  }, []);

  return (
    <div className="mt-8">
      <div className="space-y-6">
        <div className="space-y-2">
          <div className="relative">
            <Input
              type="email"
              value={userEmail}
              placeholder="Email"
              onChange={(e) => setUserEmail(e.target.value)}
              className={`pl-10 ${userEmail && !ValidateEmail(userEmail) ? "border-red-500" : ""
                }`}
              onKeyDown={handleKeyDown}
            />
            <Mail className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
          </div>
        </div>

        <div className="space-y-2">
          <div className="relative">
            <Input
              type="password"
              value={userPassword}
              placeholder="Password"
              onChange={(e) => setUserPassword(e.target.value)}
              className="pl-10"
              onKeyDown={handleKeyDown}
            />
            <Lock className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <Checkbox
            id="keepLoggedIn"
            checked={keepLoggedIn}
            onCheckedChange={(checked) => setKeepLoggedIn(checked as boolean)}
          />
          <Label htmlFor="keepLoggedIn">Keep me logged in</Label>
        </div>

        <div className="space-y-4">
          <Button
            className="w-full"
            disabled={!userEmail || !userPassword || isLoading}
            onClick={handleLogin}
          >
            {isLoading ? "Logging in..." : "Login"}
          </Button>

          <Button
            variant="ghost"
            className="w-full"
            onClick={() => setIsLogin(false)}
          >
            Request an Account
          </Button>
        </div>
      </div>
    </div>
  );
};
