import React, { Fragment, useEffect } from "react";
import { AppMasterTable } from "../../../components/app.master.table";
import {
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { IFilterCategory, IMasterColumns, ITableFilters } from "../../module.interface";
import { IESDoc } from "../../../app.interface";
import { FetchPermissionsList } from "../../../services/settings.service";
import { toast } from "../../../state/snackbar";
import { AppDrawer } from "../../../views/Drawer";
import { useBehaviourSubject } from "../../../hooks/user-obsevrable";
import { user$ } from "../../../state/user";
import { UUIDGenerator } from "../../../util/uuid.generator";
import useDeepCompareEffect from "use-deep-compare-effect";
import { FullScreenModal } from "../../../components/app.full-screen.modal";
import { isPermissionValid } from "../../../util/user-permission-validator";
import { SelectionSearch } from "../../../components/app.selection_search";
import { CopiableText } from "../../../components/copiable_text";
import MemberDetail from "./views/detail.request.view";
import EditRequestRecord from "./controllers/edit.request.view";
import { InternalGetRequestsData } from "../../../services/admin-panel/internal.request.service";
import { AppFiltersChips } from "@/components/app.filter_chips";
import { CreateEmployeeController } from "@/modules/settings/manage_users/add_user/create_employee.index";

export const filtersCategory: IFilterCategory[] = [
  {
    field_name: "created_by",
    title: "Created By",
    aggs_field_name: "CREATED_BY",
  },
  {
    field_name: "module",
    title: "Module",
    aggs_field_name: "MODULE",
  },
  {
    field_name: "action",
    title: "Action",
    aggs_field_name: "ACTION",
  },
  {
    field_name: "status",
    title: "Status",
    aggs_field_name: "STATUS",
  },
];

export const InternalManageRequestsController: React.FC = () => {
  const module_permission = "inventory"; //TODO: change it to 'internal.requests'
  const [masterColumns] = React.useState<IMasterColumns[]>([
    {
      masterColumnName: "value",
      masterColumnTitle: "Scrap Name",
      renderType: "text",
      cellRender: (params) => (
        <CopiableText textToCopy={params?.value?.member_name || "-"} title={"Scrap Name"}>
          <Typography
            sx={{ fontSize: 13, fontWeight: 500 }}
            color={"text.primary"}
          >
            {params.value.member_name}
          </Typography>
        </CopiableText>
      ),
    },
    {
      masterColumnName: "value",
      masterColumnTitle: "CR Number",
      renderType: "text",
      cellRender: (params) => (
        <CopiableText textToCopy={params?.value?.cr_number || "-"} title={"CR Number"}>
          <Typography
            sx={{ fontSize: 13, fontWeight: 500 }}
            color={"text.primary"}
          >
            {params.value.cr_number}
          </Typography>
        </CopiableText>
      ),
    },
    {
      masterColumnName: "value",
      masterColumnTitle: "Email",
      renderType: "text",
      cellRender: (params) => (
        <CopiableText textToCopy={params?.value?.email || "-"} title={"Email"}>
          <Typography
            sx={{ fontSize: 13, fontWeight: 500 }}
            color={"text.primary"}
          >
            {params.value.email}
          </Typography>
        </CopiableText>
      ),
    },
    {
      masterColumnName: "value",
      masterColumnTitle: "Conatact Number",
      renderType: "text",
      cellRender: (params) => (
        <CopiableText textToCopy={params?.value?.contact_number || "-"} title={"Contact Number"}>
          <Typography
            sx={{ fontSize: 13, fontWeight: 500 }}
            color={"text.primary"}
          >
            {params.value.contact_number}
          </Typography>
        </CopiableText>
      ),
    },
    {
      masterColumnName: "status",
      masterColumnTitle: "Status",
      renderType: "text",
      cellRender: (params) => (
        <CopiableText textToCopy={params?.status || "-"} title={"Status"}>
          <Typography
            sx={{ fontSize: 13, fontWeight: 500 }}
            color={"text.primary"}
          >
            {params.status}
          </Typography>
        </CopiableText>
      ),
    },
    {
      masterColumnName: "_id",
      masterColumnTitle: "",
      renderType: "text",
      cellRender: (params) => {
        return (
          <Box>
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={() => {
                setAddRequest(true);
                setSelectedUser(params);
              }}
            >
              Approve
            </Button>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              onClick={() => {
                setSelectedUser(params);
              }}
            >
              Edit
            </Button>
          </Box>
        );
      },
    },
  ]);

  const [selectedSearchField, setSelectedSearchField] = React.useState<{
    fieldName: string;
    title: string;
    icon: string;
    hideOptions?: boolean;
  }>({
    fieldName: "status",
    icon: "corporate_fare",
    title: "Status",
    hideOptions: false,
  });

  const [totalDocs] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [pageIndex, setPageIndex] = React.useState<number>(0);
  const [sortOrder, setSortOrder] = React.useState<"desc" | "asc">("desc");
  const [sortField, setSortField] = React.useState<string>("created_at");

  const [docs, setDocs] = React.useState<IESDoc[]>([]);
  const [checkedDocs, setCheckedDocs] = React.useState<IESDoc[]>([]);
  const [selectedDoc, setSelectedDoc] = React.useState<IESDoc>();
  const [selectedUser, setSelectedUser] = React.useState<IESDoc>();
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [filterList, setFilterList] = React.useState<ITableFilters[]>([{
    id: "module_member",
    title: "Moduel-member",
    field: "module",
    value: "member",
    operator: "is one of",
  }]);
  const [searchVal, setSearchVal] = React.useState<string>("");

  const [updateId, setUpdateId] = React.useState<string>("");
  const [permissionsStructureList, setPermissionsStructureList] =
    React.useState<string[]>([]);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [addRequest, setAddRequest] = React.useState<boolean>(false);
  const user = useBehaviourSubject(user$);

  const fetchData = () => {
    if (user) {
      setLoading(true);
      InternalGetRequestsData(
        user.member_id,
        pageIndex,
        pageSize,
        searchQuery,
        undefined,
        undefined,
        undefined,
        sortField,
        sortOrder,
        filterList
      )
        .then((res) => {
          setDocs(res.data);
        })
        .catch(() => {
          toast(
            "Error happened while getting the users data, please try again later.",
            "error"
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const fetchUserPermissionsData = () => {
    FetchPermissionsList(1000)
      .then((permissionsResponse) => {
        const initialData: IESDoc[] = permissionsResponse.data;
        setPermissionsStructureList(
          initialData.map((v) => {
            return v.name;
          })
        );
      })
      .catch(() => {
        toast("Error happened while fetching permissions", "error");
      });
  };
  useEffect(fetchUserPermissionsData, []);
  useDeepCompareEffect(fetchData, [
    updateId,
    searchQuery,
    pageSize,
    pageIndex,
    sortOrder,
    sortField,
    filterList,
    user,
  ]);
  const onCloseModel = () => {
    setSelectedDoc(undefined);
    setSelectedUser(undefined);
  };

  return (
    <Fragment>
      <Box className="header-container" sx={{ mb: 2 }}>
        <Stack spacing={2} sx={{ mt: 4 }}>
          <Grid container justifyContent={"center"}>
            <Grid item>
              <Typography
                color={"primary"}
                sx={{ fontSize: 25, fontWeight: 600, textAlign: "center" }}
              >
                Request Manager
              </Typography>
              <Typography
                color="text.secondary"
                sx={{ fontSize: 14, textAlign: "center", paddingTop: 0.5 }}
              >
                View and manage your Request.
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent={"center"}>
            <Grid item xl={5} lg={5} md={9} sm={10} xs={12} sx={{ mb: 2 }}>
              <SelectionSearch
                rounded
                dataSource="ds_staff" //TODO: need to change
                availableSearchFields={[
                  {
                    fieldName: "status",
                    icon: "status", //TODO: choose correct icon
                    title: "Status",
                    hideOptions: false,
                  },
                ]}
                setSearchQuery={setSearchQuery}
                selectedSearchField={selectedSearchField}
                setSelectedSearchField={setSelectedSearchField}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>


      <Box className="body-container">
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {/* table */}
            <Paper sx={{ paddingTop: 1, mt: 1 }}>
              <Grid container justifyContent={"space-between"} sx={{ mt: 2, mb: 1 }}>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                  <AppFiltersChips
                    currentFilters={filterList}
                    //TODO: pass dataSource
                    dataSource="ds_feed_inventory_parts"
                    filtersCategories={filtersCategory}
                    onChangeFilters={setFilterList}
                  />
                </Grid>
                <Grid item>
                  <Grid container spacing={1} justifyContent={"flex-end"}>
                    <Grid item alignSelf={"center"}>
                      <Tooltip title="Refresh">
                        <IconButton
                          onClick={() => {
                            setUpdateId(UUIDGenerator());
                          }}
                        >
                          <Icon color="primary">refresh</Icon>
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item alignSelf={"center"}>
                      <Tooltip
                        title={
                          !isPermissionValid(`${module_permission}.add`)
                            ? "You dont have permission to add records, contact your system admin"
                            : "Add New Employee"
                        }
                      >
                        <span>
                          <Button
                            onClick={() => {
                              setAddRequest(true);
                            }}
                            disabled={
                              !isPermissionValid(`${module_permission}.add`)
                            }
                            variant="contained"
                            color="primary"
                          >
                            <Typography
                              sx={{ fontSize: 13, textTransform: "none" }}
                            >
                              Add Request
                            </Typography>
                          </Button>
                        </span>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Divider sx={{ mt: 1 }} />

              <AppMasterTable
                loading={loading}
                dataSource={"requests"}
                name={"requests"}
                title={"requests"}
                colDefs={masterColumns}
                docs={docs}
                totalDocs={totalDocs}
                pageIndex={pageIndex}
                pageSize={pageSize}
                sortField={sortField}
                sortOrder={sortOrder}
                onChangeSortField={setSortField}
                onChangeSortOrder={setSortOrder}
                onChangePageIndex={setPageIndex}
                onChangePageSize={setPageSize}
                onChangeSelectedDoc={setSelectedDoc}
                checkedDocs={checkedDocs}
                enablePagination={true}
                enableCheckBox={true}
                enableSelection={true}
                enableBrandColum={false}
                onChangeCheckedDocs={setCheckedDocs}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>

      {/* detail */}
      {selectedDoc ? (
        <AppDrawer
          anchor={"right"}
          open={true}
          title={`${selectedDoc.action} ${selectedDoc.module}`}
          subTitle={selectedDoc.created_by}
          onClose={onCloseModel}
        >
          <MemberDetail doc={selectedDoc} colDoc={masterColumns.slice(0, -1)} />
        </AppDrawer>
      ) : null}

      {selectedUser ? (
        <AppDrawer
          anchor={"right"}
          open={true}
          title={selectedUser.actin}
          subTitle={selectedUser.module}
          onClose={onCloseModel}
        >
          <EditRequestRecord
            doc={selectedUser}
            setUpdateID={setUpdateId}
            onCloseModel={onCloseModel}
            permissionsStructure={permissionsStructureList}
          />
        </AppDrawer>
      ) : null}

      <FullScreenModal
        isOpen={addRequest}
        onClose={() => {
          setAddRequest(false);
        }}
        width="lg"
        title="Add Employee"
        subTitle="Add New Employee Account"
        content={
          <CreateEmployeeController
            initials={selectedUser}
            onClose={() => {
              setAddRequest(false);
              setUpdateId(UUIDGenerator());
            }}
          />
        }
      />
    </Fragment>
  );
};
