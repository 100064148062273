import React, { Fragment, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { isEmpty } from "lodash";
import { PaperTiledWithIcon } from "../../../components/tiled-paper";
import { SetRolePermissions } from "../views/set-role-permissions.view";
import {
  CreateStaffRecord,
  FetchPermissionsList,
} from "../../../../services/settings.service";
import { toast } from "../../../../state/snackbar";
import { IESDoc } from "../../../../app.interface";
import { LoadingButton } from "@mui/lab";
import { useBehaviourSubject } from "../../../../hooks/user-obsevrable";
import { user$ } from "../../../../state/user";
import { RenderUrl } from "../../../../util/cell-renderer/renderers";
import { initialData } from "@/modules/part/part.index";

interface ICreateEmployeeController {
  onClose: () => void;
  initials?: any;
}
export const CreateEmployeeController: React.FC<ICreateEmployeeController> = ({
  onClose,
  initials,
}) => {
  const theme = useTheme();
  const user = useBehaviourSubject(user$);
  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [confirmPassword, setConfirmPassword] = React.useState<string>("");
  const [whatsappNumber, setWhatsappNumber] = React.useState<string>("");
  const [mobileNumber, setMobileNumber] = React.useState<string>("");

  const [permissionsStructure, setPermissionsStructure] = React.useState<
    string[]
  >([]);
  const [resultedPermissions, setResultedPermissions] = React.useState<
    string[]
  >(["dashboard.view", "inventory.view", "inventory.add", "inventory.sunnary"]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [activeStep, setActiveStep] = React.useState<number>(0);
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const fetchUserPermissionsData = () => {
    FetchPermissionsList(1000)
      .then((permissionsResponse) => {
        const initialData: IESDoc[] = permissionsResponse.data;
        setPermissionsStructure(
          initialData.map((v) => {
            return v.name;
          })
        );
      })
      .catch(() => {
        toast("Error happened while fetching permissions", "error");
      });
  };

  const handleSubmit = () => {
    if (user) {
      setLoading(true);
      CreateStaffRecord(
        email,
        name,
        password,
        "EMPLOYEE",
        "Employee",
        resultedPermissions,
        user.member_id,
        user.member_name,
        false,
        false,
        false
      )
        .then(() => {
          toast("Employee Record Created Successfully", "success");
          onClose();
        })
        .catch(() => {
          toast("Error happened while creating the employee record", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchUserPermissionsData()
    if (initials?.value?.email) {
      setEmail(initials.value.email);
    }
    if (initials?.value?.member_name) {
      setName(initials.value.member_name);
    }
    if (initials?.value?.contact_number) {
      setMobileNumber(initials.value.contact_number);
      // Also set whatsapp number to the same contact number by default
      setWhatsappNumber(initials.value.contact_number);
    }
  }, [initials]);
  return (
    <Fragment>
      <Grid container justifyContent={"space-between"} sx={{ minHeight: 500 }}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step>
              <StepLabel>Personal Information</StepLabel>
              <StepContent>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      size="small"
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: "13px",
                          fontWeight: 500,
                        },
                      }}
                      placeholder="Employee Name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      size="small"
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: "13px",
                          fontWeight: 500,
                        },
                      }}
                      placeholder="Phone Number"
                      value={mobileNumber}
                      onChange={(e) => {
                        setMobileNumber(e.target.value);
                      }}
                      type="number"
                      fullWidth
                      helperText="optional"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      size="small"
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: "13px",
                          fontWeight: 500,
                        },
                      }}
                      placeholder="Whatsapp Number"
                      value={whatsappNumber}
                      onChange={(e) => {
                        setWhatsappNumber(e.target.value);
                      }}
                      type="number"
                      fullWidth
                      helperText="optional"
                    />
                  </Grid>
                  {!isEmpty(whatsappNumber) ? (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Typography sx={{ fontSize: 13, fontWeight: 500 }}>
                        {RenderUrl(`https://wa.me/${whatsappNumber}`)}
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Account Settings</StepLabel>
              <StepContent>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      size="small"
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: "13px",
                          fontWeight: 500,
                        },
                      }}
                      placeholder="Email"
                      value={email}
                      type="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      helperText="This email will be used for login"
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      size="small"
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: "13px",
                          fontWeight: 500,
                        },
                      }}
                      placeholder="Account Password"
                      helperText="This password will be used for login"
                      value={password}
                      type="password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      size="small"
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: "13px",
                          fontWeight: 500,
                        },
                      }}
                      placeholder="Confirm Password"
                      helperText="Re-write the password"
                      value={confirmPassword}
                      type="password"
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                      fullWidth
                      error={confirmPassword !== password}
                    />
                  </Grid>{" "}
                </Grid>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Allowed Permissions</StepLabel>
              <StepContent>
                <SetRolePermissions
                  roleName={""}
                  permissionsStructureList={permissionsStructure}
                  resultedPermissions={resultedPermissions}
                  setResultedPermissions={setResultedPermissions}
                  miniView={true}
                  editView={true}
                  resultView={false}
                  isUpdating={true}
                />
              </StepContent>
            </Step>
          </Stepper>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{ justifyContent: "flex-end", display: "flex" }}
        >
          <Box
            sx={{
              border: `2px solid ${theme.palette.primary.main}`,
              borderRadius: 2,
              minHeight: 400,
              width: "80%",
              mt: 2,
            }}
          >
            <Stack spacing={2} sx={{ padding: 2, position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  top: -40,
                  right: "0px",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Grid container justifyContent={"center"}>
                  <Grid item>
                    <Avatar
                      sx={{
                        width: 80,
                        height: 80,
                        backgroundColor: theme.palette.primary.main,
                        fontWeight: 600,
                        border: `10px solid ${theme.palette.background.paper}`,
                      }}
                    >
                      {isEmpty(name)
                        ? "??"
                        : `${!isEmpty(name) ? name[0] : ""}`}
                    </Avatar>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ paddingTop: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <PaperTiledWithIcon
                      color="primary"
                      icon="badge"
                      isDefault
                      loading={false}
                      title="Employee Name"
                      value={name}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <PaperTiledWithIcon
                      color="primary"
                      icon="email"
                      isDefault
                      loading={false}
                      title="Employee Email"
                      value={email}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <PaperTiledWithIcon
                      color="primary"
                      icon="lock"
                      isDefault
                      loading={false}
                      title="Password"
                      value={password}
                      isMasked={true}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <SetRolePermissions
                      roleName={""}
                      permissionsStructureList={permissionsStructure}
                      resultedPermissions={resultedPermissions}
                      setResultedPermissions={setResultedPermissions}
                      miniView={true}
                      editView={false}
                      resultView={true}
                      isUpdating={true}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </Box>
        </Grid>
        <Grid container justifyContent={"flex-start"} spacing={2}>
          <Grid item>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              disabled={
                activeStep === 2 &&
                (isEmpty(name) ||
                  isEmpty(email) ||
                  isEmpty(password) ||
                  isEmpty(confirmPassword) ||
                  isEmpty(resultedPermissions))
              }
              loading={activeStep === 2 && loading}
              onClick={activeStep === 2 ? handleSubmit : handleNext}
            >
              {activeStep === 2 ? "Submit" : "Next"}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};
