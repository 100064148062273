import { useAuthStore } from './store/auth.store';

export const isLoggedIn = () => {
  const user = useAuthStore.getState().user;
  return !!user;
};

export const logout = () => {
  const { logout: storeLogout } = useAuthStore.getState();
  storeLogout();
};
