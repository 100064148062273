import { create } from "zustand";
import { devtools } from "zustand/middleware";

type TableLocalStore = {
  createDialog: JSX.Element
  setCreateDialog: (el: JSX.Element) => void
}
export const useTableLocalStore = create<TableLocalStore>()(
  devtools((set) => ({
    createDialog: <></>,
    setCreateDialog: (el: JSX.Element) => set({ createDialog: el }),
  })))
