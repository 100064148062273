import AniChars from "@/animations/text/AniCharachter"
import { useBehaviourSubject } from "@/hooks/user-obsevrable";
import { cn } from "@/lib/utils";
import { user$ } from "@/state/user";
import { useEffect } from "react";
import { PartTicketDetail } from "./view/part-ticket.detial";
import { usePartTicket } from "./store/part-ticket.store";
import { PartTicketTable } from "./table/part-ticket.table";
import { PriceOfferDialog } from "./view/part-ticket.dialog";
import { motion } from 'framer-motion';
import { useMembershipStore } from "../membership/store/membership.store";
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import { PartTicketAvailableTable } from "./table/part-ticket.available.table";
import { PartTicketPendingTable } from "./table/part-ticket.pending.table";
import { PartTicketSoldTable } from "./table/part-ticket.sold.table";

type ITabData = {
    label: string
    value: string
    children: React.ReactNode
    disabled?: boolean
}
const TabsData: ITabData[] = [
    {
        label: "Available",
        value: "avilable",
        children: <PartTicketAvailableTable />,
    },
    {
        label: "Pending",
        value: "pending",
        children: <PartTicketPendingTable />,
    },
    {
        label: "Sold",
        value: "sold",
        children: <PartTicketSoldTable />,
    },
]
const partTicketDetailVariants = {
    open: {
        width: '33%',
        opacity: 1,
        transition: {
            duration: 0.5,
            ease: "easeInOut"
        },
    },
    closed: {
        width: 0,
        opacity: 0,
        transition: {
            duration: 0.5,
            ease: "easeInOut"
        },
    },
};
const tableVariants = {
    open: {
        width: '100%',
        opacity: 1,
        transition: {
            duration: 0.5,
            ease: "easeInOut"
        },
    },
    closed: {
        width: '66%',
        opacity: 1,
        transition: {
            duration: 0.5,
            ease: "easeInOut"
        },
    },
};

export function PartTicketController() {
    // TODO: move user and membership to be in context
    const user = useBehaviourSubject(user$);
    const {
        fetchData,
    } = useMembershipStore()
    const {
        subscribeToUpdates,
        selectedPartTicket,
        addDemo,
    } = usePartTicket();

    useEffect(() => {
        if (user)
            fetchData({ member_id: user.member_id })
    }, [])
    useEffect(() => {
        const unsubscribe = subscribeToUpdates();
        return () => unsubscribe();
    }, []);

    return (
        <>
            <PriceOfferDialog />
            <div className="hidden flex-col md:flex">
                <div className="flex-1 space-y-4 p-8 pt-6">
                    <Tabs defaultValue="avilable" className="space-y-4">
                        <div className="flex items-center gap-4 justify-start space-y-2" >
                            <AniChars text="Part Requests" height="h-9" className=" text-3xl font-bold tracking-tight" />
                            <TabsList>
                                {TabsData.map(({ label, value, disabled }) => (
                                    <TabsTrigger
                                        key={value}
                                        value={value}
                                        className="text-sm font-medium"
                                        disabled={disabled}
                                    >
                                        {label}
                                    </TabsTrigger>
                                ))}
                            </TabsList>
                        </div>
                        {TabsData.map((tab) => (
                            <TabsContent
                                key={tab.value}
                                value={tab.value}
                            >
                                <div className="flex flex-row w-full space-x-2 relative">
                                    <motion.div
                                        initial={false}
                                        className={cn("pt-2 flex flex-col")}
                                        variants={tableVariants}
                                        animate={selectedPartTicket ? 'closed' : 'open'}
                                    >
                                        {tab.children}
                                    </motion.div>
                                    <motion.div
                                        initial={false}
                                        className="absolute right-0 top-0 p-2 flex flex-col justify-end space-y-2"
                                        variants={partTicketDetailVariants}
                                        animate={selectedPartTicket ? 'open' : 'closed'}
                                    >
                                        {selectedPartTicket && (
                                            <PartTicketDetail />
                                        )}
                                    </motion.div>
                                </div>
                            </TabsContent>
                        ))}
                    </Tabs>
                </div>
            </div>
        </>
    );
}
