import React, { useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { PartTickitPriceSuggestion, usePartTicket } from '../store/part-ticket.store';
import { useBehaviourSubject } from '@/hooks/user-obsevrable';
import { user$ } from '@/state/user';
import { Separator } from "@/components/ui/separator";
import { Card, CardContent } from "@/components/ui/card";
import { motion, AnimatePresence } from "framer-motion";
import {
    CarFrontIcon,
    TagIcon,
    AlertCircleIcon,
    DollarSignIcon,
    CalendarIcon,
    CheckCircleIcon,
    CheckIcon,
    XCircleIcon,
    PhoneIcon,
    XIcon
} from "lucide-react";
import { useMembershipStore } from '../../membership/store/membership.store';
import { Badge } from "@/components/ui/badge";

export function PriceOfferDialog() {
    const [price, setPrice] = useState<string>('');
    const [error, setError] = useState<string>('');
    const user = useBehaviourSubject(user$);
    const {
        member
    } = useMembershipStore()
    const {
        selectedPartTicket,
        offer,
        isSubmitting,
        isDialogOpen,
        setDialogOpen,
        whatsappNumber,
        setWhatsappNumber,
        validateWhatsappNumber,
        saveWhatsappNumber,
        showWhatsappInput,
        setShowWhatsappInput,
        getActiveWhatsappNumber,
        userSuggestion,
        otherSuggestions,
        updateSuggestions,
        deleteSuggestion
    } = usePartTicket();

    // Set initial price if user has a suggestion
    React.useEffect(() => {
        if (selectedPartTicket && user) {
            updateSuggestions(user.member_id);
        }
    }, [selectedPartTicket, user, updateSuggestions]);

    React.useEffect(() => {
        if (userSuggestion && isDialogOpen) {
            setPrice(userSuggestion.price.toString());
        } else if (!isDialogOpen) {
            // Reset price when dialog closes
            setPrice('');
        }
    }, [userSuggestion, isDialogOpen]);

    // Update suggestions when dialog opens
    React.useEffect(() => {
        if (isDialogOpen && user && selectedPartTicket) {
            updateSuggestions(user.member_id);
        }
    }, [isDialogOpen, user, selectedPartTicket, updateSuggestions]);

    if (!selectedPartTicket) return null;

    const activeWhatsappNumber = getActiveWhatsappNumber(member?.contact_number);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');

        if (!price || isNaN(Number(price)) || Number(price) <= 0) {
            setError('Please enter a valid price');
            return;
        }

        // If we don't have a contact number and not showing the input yet
        if (!member?.contact_number && !showWhatsappInput) {
            setShowWhatsappInput(true);
            return;
        }

        // If we're showing the WhatsApp input, validate it
        if (showWhatsappInput) {
            if (!whatsappNumber || !validateWhatsappNumber(whatsappNumber)) {
                setError('Please enter a valid WhatsApp number');
                return;
            }
            // Save valid number
            saveWhatsappNumber(whatsappNumber);
        }

        try {
            await offer(
                user!,
                Number(price),
                showWhatsappInput ? whatsappNumber : member?.contact_number
            );
            setPrice('');
            setWhatsappNumber('');
            setShowWhatsappInput(false);
        } catch (error) {
            setError('Failed to submit price suggestion. Please try again.');
        }
    };

    return (
        <Dialog open={isDialogOpen} onOpenChange={setDialogOpen}>
            <DialogContent
                className="sm:max-w-[500px] max-h-[90vh] overflow-y-auto"
            >
                <DialogHeader>
                    <DialogTitle className="text-2xl flex items-center gap-2 text-primary">
                        <DollarSignIcon className="w-7 h-7" />
                        {userSuggestion ? 'Edit Your Offer' : 'Make an Offer'}
                    </DialogTitle>
                    <DialogDescription className="text-base">
                        {userSuggestion
                            ? 'Update your price suggestion for:'
                            : 'You are making an offer for:'}
                    </DialogDescription>
                </DialogHeader>

                {/* Part Details Section */}
                <Card className="bg-gray-50/50 border-primary/10">
                    <CardContent className="p-4 space-y-3">
                        <motion.div
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.1 }}
                        >
                            <h3 className="font-semibold text-xl mb-2 text-primary">{selectedPartTicket.car_name}</h3>
                            <div className="flex items-center gap-2 text-gray-700">
                                <CarFrontIcon className="w-5 h-5 text-primary" />
                                <span className="font-medium">
                                    {selectedPartTicket.part_name}
                                </span>
                            </div>
                        </motion.div>
                        {selectedPartTicket.description && (
                            <motion.p
                                className="text-sm text-gray-600 mt-2 leading-relaxed"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 0.2 }}
                            >
                                {selectedPartTicket.description}
                            </motion.p>
                        )}
                    </CardContent>
                </Card>

                <Separator className="my-4" />

                {/* Price Input Form */}
                <form onSubmit={handleSubmit} className="space-y-4">
                    {userSuggestion && (
                        <motion.div
                            className="bg-primary/5 p-4 rounded-lg border border-primary/10"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                        >
                            <h4 className="text-sm font-medium text-primary mb-1">Your Current Offer</h4>
                            <div className="text-2xl font-semibold text-primary flex items-center gap-2">
                                <DollarSignIcon className="w-5 h-5" />
                                {userSuggestion.price} BD
                            </div>
                            <div className="flex items-center justify-between mt-2">
                                <p className="text-xs text-gray-500">
                                    Suggested on {new Date(userSuggestion.created_at).toLocaleDateString()}
                                </p>
                                <div className="flex items-center gap-2">
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        size="sm"
                                        onClick={async () => {
                                            if (!user) return;
                                            try {
                                                await deleteSuggestion(user.member_id);
                                                setDialogOpen(false);
                                            } catch (error) {
                                                setError('Failed to delete suggestion');
                                            }
                                        }}
                                        className="text-destructive hover:text-destructive hover:bg-destructive/10"
                                        disabled={isSubmitting}
                                    >
                                        <XCircleIcon className="w-4 h-4 mr-1" />
                                        Undo
                                    </Button>
                                </div>
                            </div>
                        </motion.div>
                    )}

                    <motion.div
                        className="space-y-2"
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.3 }}
                    >
                        <Label htmlFor="price" className="text-base font-semibold flex items-center gap-2">
                            <DollarSignIcon className="w-4 h-4 text-primary" />
                            {userSuggestion ? 'Update Your Price Offer (BD)' : 'Your Price Offer (BD)'}
                        </Label>
                        <div className="relative">
                            <Input
                                id="price"
                                type="number"
                                step="0.1"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                className="text-lg pr-12 border-primary/20 focus:border-primary"
                                placeholder="Enter your price in BD"
                            />
                            <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 font-medium">
                                BD
                            </span>
                        </div>
                    </motion.div>

                    {/* WhatsApp Number Section - Always visible */}
                    <motion.div className="space-y-2">
                        <Label className="text-base font-semibold flex items-center gap-2">
                            <PhoneIcon className="w-4 h-4 text-primary" />
                            Contact Number
                        </Label>
                        {showWhatsappInput ? (
                            <div className="space-y-2">
                                <div className="flex items-center gap-1">
                                    <div className="relative flex-1">
                                        <Input
                                            id="whatsapp"
                                            type="tel"
                                            value={whatsappNumber}
                                            onChange={(e) => setWhatsappNumber(e.target.value)}
                                            className="text-lg pl-8 pr-4 border-primary/20 focus:border-primary"
                                            placeholder="Enter your WhatsApp number"
                                        />
                                        <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
                                            +
                                        </span>
                                    </div>
                                    <div className="flex">
                                        <Button
                                            type="button"
                                            variant="ghost"
                                            size="sm"
                                            onClick={() => {
                                                setShowWhatsappInput(false);
                                                setWhatsappNumber(activeWhatsappNumber || '');
                                            }}
                                            className="text-destructive hover:text-destructive hover:bg-destructive/10 h-[42px] px-3 transition-colors duration-200"
                                        >
                                            <XIcon className="w-4 h-4" />
                                        </Button>
                                        <Button
                                            type="button"
                                            variant="ghost"
                                            size="sm"
                                            onClick={() => {
                                                if (validateWhatsappNumber(whatsappNumber)) {
                                                    saveWhatsappNumber(whatsappNumber);
                                                    setShowWhatsappInput(false);
                                                }
                                            }}
                                            className="text-success hover:text-success hover:bg-success/10 h-[42px] px-3 transition-colors duration-200 flex items-center gap-2"
                                            disabled={!validateWhatsappNumber(whatsappNumber)}
                                        >
                                            <CheckIcon className="w-4 h-4" />
                                        </Button>
                                    </div>
                                </div>
                                <p className="text-xs text-gray-500">
                                    Enter your WhatsApp number with country code (e.g., 97336123456)
                                </p>
                            </div>
                        ) : (
                            <div className="flex items-center justify-between bg-gray-50 p-3 rounded-lg">
                                <div className="flex items-center gap-2">
                                    <span className="text-gray-600">+{activeWhatsappNumber}</span>
                                    {member?.contact_number ? (
                                        <Badge variant="outline" className="bg-primary/5 text-primary border-primary/20">
                                            Membership
                                        </Badge>
                                    ) : (
                                        <Badge variant="outline" className="bg-primary/5 text-primary border-primary/20">
                                            Saved
                                        </Badge>
                                    )}
                                </div>
                                {!member?.contact_number && (
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        size="sm"
                                        onClick={() => setShowWhatsappInput(true)}
                                        className="text-primary hover:text-primary/80"
                                    >
                                        Change
                                    </Button>
                                )}
                            </div>
                        )}
                    </motion.div>

                    {/* Existing Price Suggestions */}
                    {otherSuggestions && otherSuggestions.length > 0 && (
                        <motion.div
                            className="mt-6"
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.4 }}
                        >
                            <Label className="text-base font-semibold mb-3 flex items-center gap-2">
                                <TagIcon className="w-4 h-4 text-primary" />
                                Other Offers
                            </Label>
                            <div className="mt-2 space-y-2 max-h-[200px] overflow-y-auto pr-2">
                                {otherSuggestions.map((suggestion, index) => (
                                    <motion.div
                                        key={index}
                                        className="flex justify-between items-center text-sm bg-gray-50 p-3 rounded-lg hover:bg-gray-100 transition-all duration-200"
                                        initial={{ opacity: 0, x: -20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: index * 0.1 }}
                                    >
                                        <span className="font-medium text-primary flex items-center gap-2">
                                            <DollarSignIcon className="w-4 h-4" />
                                            {suggestion.price} BD
                                        </span>
                                        <span className="text-gray-500 flex items-center gap-2">
                                            <CalendarIcon className="w-4 h-4" />
                                            {new Date(suggestion.created_at).toLocaleDateString()}
                                        </span>
                                    </motion.div>
                                ))}
                            </div>
                        </motion.div>
                    )}

                    <AnimatePresence>
                        {error && (
                            <motion.div
                                className="flex items-center gap-2 text-red-500 text-sm mt-1 bg-red-50 p-2 rounded-md"
                                initial={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: 'auto' }}
                                exit={{ opacity: 0, height: 0 }}
                            >
                                <AlertCircleIcon className="w-4 h-4" />
                                <span>{error}</span>
                            </motion.div>
                        )}
                    </AnimatePresence>

                    <DialogFooter className="mt-6 gap-2">
                        <Button
                            type="button"
                            variant="outline"
                            onClick={() => {
                                setDialogOpen(false);
                                setPrice(userSuggestion ? userSuggestion.price.toString() : '');
                                setError('');
                            }}
                            className="gap-2"
                        >
                            <XCircleIcon className="w-4 h-4" />
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            disabled={isSubmitting}
                            className="min-w-[120px] gap-2"
                        >
                            {isSubmitting ? (
                                <>
                                    <span className="animate-spin">⏳</span>
                                    Submitting...
                                </>
                            ) : (
                                <>
                                    <CheckCircleIcon className="w-4 h-4" />
                                    {userSuggestion ? 'Update Offer' : 'Submit Offer'}
                                </>
                            )}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
}

