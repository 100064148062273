import { Button } from "@/components/ui/button"
import { useBehaviourSubject } from "@/hooks/user-obsevrable";
import { user$ } from "@/state/user";
import { usePartTicket } from "../store/part-ticket.store"
import { Badge } from "@/components/ui/badge"
import {
    CalendarIcon,
    CarFrontIcon,
    TagIcon,
    DollarSignIcon,
    ImageIcon,
    ClockIcon,
    ImageOffIcon,
    ChevronLeftIcon,
    ChevronRightIcon
} from "lucide-react"
import { motion, AnimatePresence } from "framer-motion";
import { Card, CardContent } from "@/components/ui/card";
import { useState, useEffect } from "react";
import { cn } from "@/lib/utils";

export const PartTicketDetail: React.FC = () => {
    const user = useBehaviourSubject(user$);
    const {
        selectedPartTicket,
        setDialogOpen,
        userSuggestion,
        otherSuggestions,
        updateSuggestions
    } = usePartTicket();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    // Update suggestions when component mounts or ticket changes
    useEffect(() => {
        if (selectedPartTicket && user) {
            updateSuggestions(user.member_id);
        }
    }, [selectedPartTicket, user, updateSuggestions]);

    const handleOfferClick = () => {
        if (user && selectedPartTicket) {
            setDialogOpen(true);
        }
    };

    if (!selectedPartTicket) return null;

    const fadeInUp = {
        initial: { opacity: 0, y: 20 },
        animate: { opacity: 1, y: 0 },
        transition: { duration: 0.4 }
    };

    // Get valid images (not expired)
    const validImages = selectedPartTicket.images?.filter(img => img.expiry_date > Date.now()) || [];

    const handlePrevImage = () => {
        setCurrentImageIndex((prev) =>
            prev === 0 ? validImages.length - 1 : prev - 1
        );
    };

    const handleNextImage = () => {
        setCurrentImageIndex((prev) =>
            prev === validImages.length - 1 ? 0 : prev + 1
        );
    };

    return (
        <motion.div
            className="relative border w-full flex flex-col gap-4 max-h-[80vh] bg-white rounded-xl shadow-lg"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3 }}
        >
            {/* Sticky Header */}
            <motion.div
                className="sticky top-0 z-10 bg-white border-b pt-6 px-6 pb-4"
                {...fadeInUp}
            >
                <div className="flex items-start justify-between">
                    <div>
                        <h1 className="text-3xl font-bold text-gray-900 mb-2">
                            {selectedPartTicket.car_name}
                        </h1>
                    </div>
                    {user && (
                        <Button
                            onClick={handleOfferClick}
                            className={cn(
                                "shadow-md transition-all duration-200 hover:scale-105",
                                userSuggestion
                                    ? "bg-primary/10 text-primary hover:bg-primary/20"
                                    : "bg-primary hover:bg-primary/90"
                            )}
                            size="lg"
                        >
                            <DollarSignIcon className="w-2 h-5" />
                            {userSuggestion ? 'Update Offer' : 'Make an Offer'}
                        </Button>
                    )}
                </div>
                <div className="flex items-center gap-2 text-2xl font-semibold text-gray-700">
                    <CarFrontIcon className="w-6 h-6 text-primary" />
                    {selectedPartTicket.part_name}
                </div>

                {/* Status and Date */}
                <motion.div className="flex items-center gap-3 mt-4" {...fadeInUp}>
                    <Badge variant="outline" className="text-sm px-3 py-1 bg-primary/5 hover:bg-primary/10">
                        <ClockIcon className="w-4 h-4 mr-1" />
                        Status: {selectedPartTicket.status}
                    </Badge>
                    {userSuggestion && (
                        <Badge variant="outline" className="text-sm px-3 py-1 bg-success/5 text-success hover:bg-success/10 border-success/20">
                            <DollarSignIcon className="w-4 h-4 mr-1" />
                            Your Offer: {userSuggestion.price} BD
                        </Badge>
                    )}
                </motion.div>
            </motion.div>

            {/* Scrollable Content */}
            <div className="flex flex-col gap-4 px-6 pb-6 overflow-y-auto">
                {/* Description */}
                {selectedPartTicket.description && (
                    <motion.div {...fadeInUp}>
                        <Card className="bg-gray-50/50">
                            <CardContent className="p-4">
                                <h3 className="text-lg font-semibold mb-3 flex items-center gap-2 text-primary">
                                    <TagIcon className="w-5 h-5" />
                                    Description
                                </h3>
                                <p className="text-gray-600 leading-relaxed">
                                    {selectedPartTicket.description}
                                </p>
                            </CardContent>
                        </Card>
                    </motion.div>
                )}

                {/* Image Section */}
                <motion.div {...fadeInUp}>
                    <div className="flex items-center justify-between mb-3">
                        <h3 className="text-lg font-semibold flex items-center gap-2 text-primary">
                            <ImageIcon className="w-5 h-5" />
                            Part Images
                        </h3>
                        {validImages.length > 0 && (
                            <span className="text-sm text-gray-500">
                                {currentImageIndex + 1} / {validImages.length}
                            </span>
                        )}
                    </div>

                    {validImages.length > 0 ? (
                        <div className="relative">
                            <div className="relative aspect-video w-full overflow-hidden rounded-xl hover:shadow-xl transition-shadow duration-300 group">
                                <AnimatePresence mode="wait">
                                    <motion.img
                                        key={currentImageIndex}
                                        className="object-cover w-full h-full"
                                        alt={`${selectedPartTicket.part_name} image`}
                                        src={validImages[currentImageIndex].image_url}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 0.2 }}
                                    />
                                </AnimatePresence>

                                {/* Navigation Buttons */}
                                {validImages.length > 1 && (
                                    <>
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            className="absolute left-2 top-1/2 -translate-y-1/2 bg-black/20 hover:bg-black/40 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                                            onClick={handlePrevImage}
                                        >
                                            <ChevronLeftIcon className="w-6 h-6" />
                                        </Button>
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            className="absolute right-2 top-1/2 -translate-y-1/2 bg-black/20 hover:bg-black/40 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                                            onClick={handleNextImage}
                                        >
                                            <ChevronRightIcon className="w-6 h-6" />
                                        </Button>
                                    </>
                                )}
                            </div>

                            {/* Thumbnail Navigation */}
                            {validImages.length > 1 && (
                                <div className="flex gap-2 mt-2 justify-center">
                                    {validImages.map((_, index) => (
                                        <button
                                            key={index}
                                            onClick={() => setCurrentImageIndex(index)}
                                            className={`w-2 h-2 rounded-full transition-all duration-200 ${index === currentImageIndex
                                                ? 'bg-primary scale-125'
                                                : 'bg-gray-300 hover:bg-gray-400'
                                                }`}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="relative aspect-video w-full rounded-xl bg-gray-100 flex items-center justify-center">
                            <div className="text-gray-400 flex flex-col items-center gap-2">
                                <ImageOffIcon className="w-8 h-8" />
                                <span>No valid images available</span>
                            </div>
                        </div>
                    )}
                </motion.div>

                {/* Price Suggestions */}
                {(userSuggestion || (otherSuggestions && otherSuggestions.length > 0)) && (
                    <motion.div {...fadeInUp}>
                        <Card>
                            <CardContent className="p-4">
                                <h3 className="text-lg font-semibold mb-4 flex items-center gap-2 text-primary">
                                    <DollarSignIcon className="w-5 h-5" />
                                    Price Suggestions
                                </h3>

                                <div className="space-y-4">
                                    {/* User Suggestion */}
                                    {userSuggestion && (
                                        <motion.div
                                            className="bg-primary/5 p-4 rounded-lg border border-primary/10"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                        >
                                            <div className="flex items-center justify-between">
                                                <div>
                                                    <div className="text-lg font-semibold text-primary flex items-center gap-2">
                                                        <span>Your Offer</span>
                                                        <Badge variant="outline" className="bg-primary/10 border-primary/20">
                                                            {userSuggestion.price} BD
                                                        </Badge>
                                                    </div>
                                                    <p className="text-sm text-gray-500 mt-1">
                                                        Suggested on {new Date(userSuggestion.created_at).toLocaleDateString()}
                                                    </p>
                                                </div>
                                                <Button
                                                    onClick={handleOfferClick}
                                                    variant="ghost"
                                                    size="sm"
                                                    className="text-primary hover:text-primary/90 hover:bg-primary/10"
                                                >
                                                    Update
                                                </Button>
                                            </div>
                                        </motion.div>
                                    )}

                                    {/* Other Suggestions */}
                                    {otherSuggestions && otherSuggestions.length > 0 && (
                                        <div className="space-y-2">
                                            <h4 className="text-sm font-medium text-gray-500 mb-2">
                                                Other Offers
                                            </h4>
                                            {otherSuggestions.map((suggestion, index) => (
                                                <motion.div
                                                    key={index}
                                                    className="flex items-center justify-between p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors duration-200"
                                                    initial={{ opacity: 0, y: 10 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    transition={{ delay: index * 0.1 }}
                                                >
                                                    <span className="font-medium text-primary text-lg">
                                                        {suggestion.price} BD
                                                    </span>
                                                    <span className="text-sm text-gray-500 flex items-center gap-2">
                                                        <CalendarIcon className="w-4 h-4" />
                                                        {new Date(suggestion.created_at).toLocaleDateString()}
                                                    </span>
                                                </motion.div>

                                            ))}
                                        </div>
                                    )}
                                </div>
                            </CardContent>
                        </Card>
                    </motion.div>
                )}

                {/* No Suggestions Message */}
                {!userSuggestion && (!otherSuggestions || otherSuggestions.length === 0) && (
                    <motion.div {...fadeInUp}>
                        <Card>
                            <CardContent className="p-4">
                                <div className="text-center py-6">
                                    <DollarSignIcon className="w-12 h-12 text-gray-300 mx-auto mb-3" />
                                    <h3 className="text-lg font-semibold text-gray-600 mb-2">No Offers Yet</h3>
                                    <p className="text-sm text-gray-500 mb-4">
                                        Be the first to make an offer for this part
                                    </p>
                                    {user && (
                                        <Button
                                            onClick={handleOfferClick}
                                            className="bg-primary hover:bg-primary/90"
                                        >
                                            <DollarSignIcon className="w-4 h-4 mr-2" />
                                            Make an Offer
                                        </Button>
                                    )}
                                </div>
                            </CardContent>
                        </Card>
                    </motion.div>
                )}

                {!user && (
                    <motion.div
                        className="mt-4 bg-gray-50 p-4 rounded-lg border border-gray-200"
                        {...fadeInUp}
                    >
                        <p className="text-sm text-gray-600 flex items-center gap-2">
                            <span className="text-primary">ℹ️</span>
                            Please login to make an offer
                        </p>
                    </motion.div>
                )}
            </div>
        </motion.div>
    );
}
