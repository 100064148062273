import { LocalStorageBehaviorSubject } from "../views/localstorage-behaviour-subject";

export interface IUserRecord {
  is_internal: boolean;
  member_id: string; // TODO: JUST DIE, is it number or string
  member_name: string;
  member_phone?: string; // TODO: need to be added in the sign up process
  permissions_list: string[];
  role_id: string;
  role_name: string;
  staff_email: string;
  staff_id: number;
  staff_name: string;
  created_at: number;
  is_deleted: boolean;
  is_disabled: boolean;
  last_update: number;
  _id: string;
  is_admin_account?: boolean;
}

export interface ISelectedMember {
  member_id: number;
  member_name: string;
  is_admin_account: boolean;
}

export const user$ = new LocalStorageBehaviorSubject<IUserRecord | null>(
  "logged_user_record",
  null
);

export const allowedMembers$ = new LocalStorageBehaviorSubject<number[] | null>(
  "allowed_members",
  null
);

export const selectedMember$ =
  new LocalStorageBehaviorSubject<ISelectedMember | null>(
    "selected_member",
    null
  );
