import React from "react";
import { useAuthStore } from "./store/auth.store";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { 
  Tooltip, 
  TooltipContent, 
  TooltipProvider, 
  TooltipTrigger 
} from "@/components/ui/tooltip";
import { toast } from "sonner";
import { ValidateEmail, ValidatePhone } from "../util/validator";
import { IMemberCreateRequest } from "../modules/module.interface";
import { Phone, Mail, Building, Notebook } from "lucide-react";

interface IRegisterForm {
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RegisterForm: React.FC<IRegisterForm> = ({ setIsLogin }) => {
  const [contactNumber, setContactNumber] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [userEmail, setUserEmail] = React.useState<string>("");
  const [crNumberPart1, setCrNumberPart1] = React.useState<string>("");
  const [crNumberPart2, setCrNumberPart2] = React.useState<string>("");
  const { register, isLoading } = useAuthStore();

  const handleSubmit = async () => {
    // Validate that CR parts are numeric
    const isValidCR1 = !crNumberPart1 || /^\d+$/.test(crNumberPart1);
    const isValidCR2 = !crNumberPart2 || /^\d+$/.test(crNumberPart2);
    
    if (!isValidCR1 || !isValidCR2) {
      toast.error("Error", {
        description: "CR Number must contain only digits"
      });
      return;
    }

    const combinedCrNumber = crNumberPart1 && crNumberPart2 
      ? `${crNumberPart1}-${crNumberPart2}` 
      : crNumberPart1 || "";
      
    const data: IMemberCreateRequest = {
      contact_number: contactNumber,
      member_name: name,
      email: userEmail,
      cr_number: combinedCrNumber,
    };

    try {
      await register(data);
      toast.success("Success", {
        description: "We received your request, will follow up with you soon."
      });
      setIsLogin(true);
    } catch (error) {
      toast.error("Error", {
        description: "Error while sending your request. Please try again or contact us directly"
      });
    }
  };

  const handleKeyDown = (ev: React.KeyboardEvent) => {
    if (ev.key === "Enter" && name && userEmail) {
      handleSubmit();
    }
  };

  React.useEffect(() => {
    const user = useAuthStore.getState().user;
    if (user) {
      window.location.replace("/");
    }
  }, []);

  return (
    <div className="mt-8">
      <div className="space-y-6">
        <div className="space-y-2">
          <div className="relative">
            <Input
              type="tel"
              value={contactNumber}
              placeholder="Contact Number"
              onChange={(e) => setContactNumber(e.target.value)}
              className={`pl-10 ${
                contactNumber && !ValidatePhone(contactNumber) ? "border-red-500" : ""
              }`}
              onKeyDown={handleKeyDown}
            />
            <Phone className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
          </div>
        </div>

        <div className="space-y-2">
          <div className="relative">
            <Input
              type="text"
              value={name}
              placeholder="Business Name"
              onChange={(e) => setName(e.target.value)}
              className="pl-10"
              onKeyDown={handleKeyDown}
            />
            <Building className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
          </div>
        </div>

        <div className="space-y-2">
          <div className="relative flex items-center">
            <Notebook className="absolute left-3 z-10 h-5 w-5 text-gray-500" />
            <TooltipProvider>
              <Tooltip open={crNumberPart1 ? !/^\d+$/.test(crNumberPart1) : false}>
                <TooltipTrigger asChild>
                  <Input
                    type="text"
                    inputMode="numeric"
                    value={crNumberPart1}
                    placeholder="CR Number"
                    onChange={(e) => {
                      setCrNumberPart1(e.target.value);
                    }}
                    className={`pl-10 rounded-r-none border-r-0 z-20 hover:z-20 focus:z-20 ${
                      crNumberPart1 && !/^\d+$/.test(crNumberPart1) ? "border-red-500" : ""
                    }`}
                    onKeyDown={handleKeyDown}
                  />
                </TooltipTrigger>
                <TooltipContent>
                  <p>CR Number must contain only digits</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            
            <div className="flex items-center justify-center h-9 px-2 border border-l-0 border-r-0 border-input bg-muted z-10">
              <span className="text-gray-700 font-medium text-base">-</span>
            </div>
            
            <TooltipProvider>
              <Tooltip open={crNumberPart2 ? !/^\d+$/.test(crNumberPart2) : false}>
                <TooltipTrigger asChild>
                  <Input
                    type="text"
                    inputMode="numeric"
                    value={crNumberPart2}
                    placeholder="Suffix"
                    onChange={(e) => {
                      setCrNumberPart2(e.target.value);
                    }}
                    className={`rounded-l-none z-20 hover:z-20 focus:z-20 ${
                      crNumberPart2 && !/^\d+$/.test(crNumberPart2) ? "border-red-500" : ""
                    }`}
                    onKeyDown={handleKeyDown}
                  />
                </TooltipTrigger>
                <TooltipContent>
                  <p>CR Number suffix must contain only digits</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>

        <div className="space-y-2">
          <div className="relative">
            <Input
              type="email"
              value={userEmail}
              placeholder="Email"
              onChange={(e) => setUserEmail(e.target.value)}
              className={`pl-10 ${
                userEmail && !ValidateEmail(userEmail) ? "border-red-500" : ""
              }`}
              onKeyDown={handleKeyDown}
            />
            <Mail className="absolute left-3 top-2.5 h-5 w-5 text-gray-500" />
          </div>
        </div>

        <div className="space-y-4">
          <Button
            className="w-full"
            disabled={!contactNumber || !name || !userEmail || isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? "Registering..." : "Register"}
          </Button>

          <Button
            variant="ghost"
            className="w-full"
            onClick={() => setIsLogin(true)}
          >
            Back to Login
          </Button>
        </div>
      </div>
    </div>
  );
};