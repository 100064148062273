import { PartTicketStatus, PartTickit } from "@/modules/part-ticket/store/part-ticket.store";
import axios from "axios";

export const UpdateExpiredImages = async (expiredImageData: { ticket_id: string; image_ids: string[] }) => {
  try {
    await axios.post("/part-ticket/expired-images", { expired_images: expiredImageData });
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

// TODO: Remove docTemp after testing
// TODO: Remove any
export const CreatePartTicket = async () => {
  try {
    const image = await convertImageToBase64("https://images.unsplash.com/photo-1617469767053-d3b523a0b982?q=80&w=3540&auto=format&fit=crop")
    const docTemp: PartTickit = {
      _id: "test-ticket-001",
      user_id: "user-001",
      car_make: "BMW",
      car_model: "E46",
      car_year: "2004",
      car_name: "BMW E46 2004",
      part_name: "Front Bumper M Sport",
      status: PartTicketStatus.OPEN,
      created_at: Date.now() - 3600000, // 1 hour ago
      description: "Looking for an original M Sport front bumper for my E46. Must be in good condition with minimal scratches. Preferably in Titanium Silver (Code: 354). The bumper should include fog light housings and grilles. Manufacturing date between 2002-2005 preferred.",
      image_base64: [
        image,
      ],
      suggested_prices: [
        {
          scrap_id: "scrap-002",
          scrap_wa_link: "https://wa.me/97336123470?text=Hello,%20I%20am%20interested%20in%20Front%20Bumper%20M%20Sport%20for%20BMW%203%20Series%20(E46)",
          price: 180.00,
          created_at: Date.now() - 3600000 // 1 hour ago
        },
        {
          scrap_id: "scrap-003",
          scrap_wa_link: "https://wa.me/97336123471?text=Hello,%20I%20am%20interested%20in%20Front%20Bumper%20M%20Sport%20for%20BMW%203%20Series%20(E46)",
          price: 165.00,
          created_at: Date.now() - 1800000 // 30 minutes ago
        }
      ]
    };
    const response = await axios.post("/part-ticket/add", {
      docs: docTemp,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

// Function to convert image URL to base64
function convertImageToBase64(imageUrl: string): Promise<string> {
  return new Promise((resolve, reject) => {
    // Create an image element
    const img = new Image();

    // Set up onload handler
    img.onload = () => {
      // Create a canvas element
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      // Get the canvas context and draw the image
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Could not get canvas context'));
        return;
      }

      ctx.drawImage(img, 0, 0);

      // Convert the canvas to base64
      try {
        // Get base64 string (removes the "data:image/png;base64," part if you add the second parameter to split)
        const base64String = canvas.toDataURL('image/png');
        resolve(base64String);
      } catch (error) {
        reject(error);
      }
    };

    // Set up onerror handler
    img.onerror = (error) => {
      reject(error);
    };

    // Cross-origin issues can prevent drawing the image to canvas
    img.crossOrigin = 'Anonymous';

    // Set the source to start loading
    img.src = imageUrl;
  });
}
