import { IESDoc, IESQueryResponse } from "../app.interface";
import { ITableFilters } from "../modules/module.interface";
import axios from "./axios.service";

export const InternalGetAllUsers = async <T>(
  member_id: string,
  pageIndex?: number,
  pageSize?: number,
  searchQuery?: string,
  startDate?: number,
  endDate?: number,
  dateField?: string,
  sortField?: string,
  sortOrder?: "desc" | "asc",
  filters?: ITableFilters[]
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post("/staff/list-all", {
      member_id: member_id,
      pageIndex,
      pageSize,
      searchQuery,
      startDate,
      endDate,
      dateField,
      sortField,
      sortOrder,
      filters,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

export const GetMemberUsers = async <T>(
  member_id: string,
  pageIndex?: number,
  pageSize?: number,
  searchQuery?: string,
  startDate?: number,
  endDate?: number,
  dateField?: string,
  sortField?: string,
  sortOrder?: "desc" | "asc",
  filters?: ITableFilters[]
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post("/staff/list", {
      member_id: member_id,
      pageIndex,
      pageSize,
      searchQuery,
      startDate,
      endDate,
      dateField,
      sortField,
      sortOrder,
      filters,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

export const GetUserPermissions = async <T>(
  role: string
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post("/roles/get-role-permissions", {
      role: role,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

export const FetchUserRoles = async <T>(
  pageSize?: number,
  pageIndex?: number,
  searchQuery?: string,
  startDate?: number,
  endDate?: number,
  dateField?: string,
  sortField?: string,
  sortOrder?: "desc" | "asc",
  filters?: ITableFilters[]
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post("/roles/list", {
      pageIndex,
      pageSize,
      searchQuery,
      startDate,
      endDate,
      dateField,
      sortField,
      sortOrder,
      filters,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

export const FetchPermissionsList = async <T>(
  pageSize?: number,
  pageIndex?: number,

  searchQuery?: string,
  startDate?: number,
  endDate?: number,
  dateField?: string,
  sortField?: string,
  sortOrder?: "desc" | "asc",
  filters?: ITableFilters[]
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post("/permissions/list", {
      pageIndex,
      pageSize,
      searchQuery,
      startDate,
      endDate,
      dateField,
      sortField,
      sortOrder,
      filters,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

export const UpdateCurrentUser = async (doc: IESDoc) => {
  try {
    const response = await axios.post("/staff/update", {
      doc,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const DeleteStaffRecord = async (docs: IESDoc[]) => {
  try {
    const response = await axios.post("/staff/delete", {
      docs,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const CreateStaffRecord = async (
  staff_email: string,
  staff_name: string,
  password: string,
  role_id: string,
  role_name: string,
  permissions_list: string[],
  member_id: string,
  member_name: string,
  is_deleted: boolean,
  is_disabled: boolean,
  is_internal: boolean,
  send_email: boolean = false
) => {
  try {
    const response = await axios.post("/staff/add", {
      staff_email,
      staff_name,
      password,
      role_id,
      role_name,
      permissions_list,
      member_id,
      member_name,
      is_deleted,
      is_disabled,
      is_internal,
      send_email,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
