import _ from "lodash";
import { isPermissionValid } from "../util/user-permission-validator";
import { DashboardModule } from "./dashboard/dashboard.index";
import { InventoryModuleController } from "./inventory/inventory.index";
import { IAppModules } from "./module.interface";
import { ManageUsersController } from "./settings/manage_users/manage_users.index";
import { SettingsModuleController } from "./settings/settings.index";
import { InternalRoute } from "./admin-panel/admin-panel.index";
import { InternalManageMembersController } from "./admin-panel/manage_members/manage_members.index";
import { MembershipDetails } from "./membership/membership.shadcn.index";
import { InternalManageRequestsController } from "./admin-panel/manage_requests/manage_requests.index";
import { PartsController } from "./part/part.index";
import {
  FolderCode,
  LayoutDashboard,
  Settings,
  Warehouse,
  Handshake,
} from "lucide-react"
import { PartTicketController } from "./part-ticket/part-ticket.index";
import { InternalManageRegisterRequestsController } from "./register-req/manage_requests.index";

export const AppModules: IAppModules[] = [
  {
    name: "dashboard",
    title: "Dashboard",
    icon: LayoutDashboard,
    hidden: false,
    path: "/dashboard",
    isBeta: false,
    master: false,
    permission: "dashboard.view",
    controller: DashboardModule,
    subModules: [],
    isParent: false,
  },
  {
    name: "part-ticket",
    title: "Part Request",
    icon: Handshake,
    hidden: false,
    path: "/part-ticket",
    isBeta: false,
    master: false,
    permission: "inventory.view", // TODO: update permission
    controller: PartTicketController,
    subModules: [],
    isParent: false,
  },
  {
    name: "inventory",
    title: "Inventory",
    icon: Warehouse,
    hidden: false,
    path: "/inventory",
    isBeta: false,
    master: true,
    permission: "inventory.view",
    controller: InventoryModuleController,
    isParent: true,
    subModules: [
      // {
      //   name: "part",
      //   title: "Parts",
      //   // icon: "directions_car",
      //   hidden: false,
      //   path: "/inventory/part",
      //   isBeta: false,
      //   master: false,
      //   permission: "inventory.view",
      //   controller: TyresController,
      //   subModules: [],
      //   isParent: false,
      // },
      {
        name: "tyres",
        title: "Tyres",
        // icon: "directions_car",
        hidden: false,
        path: "/inventory/tyres",
        isBeta: false,
        master: false,
        permission: "inventory.view",
        controller: PartsController,
        subModules: [],
        isParent: false,
      },
    ],
  },
  {
    name: "settings",
    title: "Settings",
    icon: Settings,
    hidden: false,
    path: "/settings",
    isBeta: false,
    master: true,
    permission: "settingss.view",
    controller: SettingsModuleController,
    isParent: true,
    subModules: [
      {
        name: "membership",
        title: "Company Detail",
        //icon: "",
        hidden: false,
        path: "settings/membership",
        isBeta: false,
        master: false,
        permission: "inventorys.view", //TODO: update permission
        controller: MembershipDetails,
        subModules: [],
        isParent: false,
      },
    ],
  },
  {
    isInternal: true,
    name: "internal",
    title: "Internal",
    icon: FolderCode,
    hidden: false,
    path: "/internal",
    isBeta: false,
    master: true,
    permission: "settings.view",
    controller: InternalRoute,
    isParent: true,
    subModules: [
      {
        name: "register-req",
        title: "Register Request",
        //icon: "assignment_ind",
        hidden: false,
        path: "/internal/register-req",
        isBeta: false,
        master: false,
        permission: "users.view",
        controller: InternalManageRegisterRequestsController,
        subModules: [],
        isParent: false,
      },
      {
        name: "users",
        title: "Manage Users",
        //icon: "assignment_ind",
        hidden: false,
        path: "/internal/users",
        isBeta: false,
        master: false,
        permission: "users.view",
        controller: ManageUsersController,
        subModules: [],
        isParent: false,
      },
      {
        isInternal: true,
        name: "company",
        title: "Manage Companies",
        //icon: "assignment_ind",
        hidden: false,
        path: "/internal/members",
        isBeta: false,
        master: false,
        permission: "company.view",
        controller: InternalManageMembersController,
        subModules: [],
        isParent: false,
      },
      {
        isInternal: true,
        name: "requests",
        title: "Manage Requests",
        icon: "assignment_ind",
        hidden: false,
        path: "/internal/requests",
        isBeta: false,
        master: false,
        permission: "users.view",
        controller: InternalManageRequestsController,
        subModules: [],
        isParent: false,
      },
    ],
  },
];

export const receiveModules = (): IAppModules[] => {
  const resultedModules: IAppModules[] = [];

  AppModules.forEach((module) => {
    const x = _.cloneDeep(module);
    let isModuleVisible = recursivePermissionCheck(module);

    const allowedSubModules = recieveSubModules(module.subModules);
    if (module.isParent === true && allowedSubModules.length === 0) {
      isModuleVisible = false;
    }
    x.subModules = allowedSubModules;
    if (isModuleVisible === true) {
      resultedModules.push(x);
    }
  });

  return resultedModules;
};

const recieveSubModules = (subModules: IAppModules[]): IAppModules[] => {
  let resultedSubModules: IAppModules[] = [];

  subModules.forEach((subModule) => {
    if (recursivePermissionCheck(subModule)) {
      resultedSubModules.push(subModule);
    }
  });

  return resultedSubModules;
};
const recursivePermissionCheck = (module: IAppModules): boolean => {
  let isAllowed = isPermissionValid(module.permission);
  if (module.subModules.length > 0) {
    module.subModules.forEach((subModule) => {
      if (recursivePermissionCheck(subModule) === true) {
        isAllowed = true;
      }
    });
  }

  return isAllowed;
};
