import { Box, Container, Grid, Paper, Stack, Typography, useTheme, alpha, Button } from "@mui/material";
import React from "react";
import logo from "../assets/logo/logo.png";
import venLogo from "../assets/logo/venestusy-logo.png";
import "./styles.css";
import { RegisterForm } from "./register-form";
import Lottie from "react-lottie";
import animationData from "../assets/lottie/people-producing-transparent.json";
import { LoginForm } from "./login";
import { SocialMediaXLogo } from "../assets/svg/social-media.x";
import { SocialMediaFacebookLogo } from "../assets/svg/social-media.facebook";
import { SocialMediaInstagramLogo } from "../assets/svg/social-media.instagram";
import { SocialMediaTiktokLogo } from "../assets/svg/social-media.tiktok";

export const AuthenticationPage: React.FC = () => {
  const theme = useTheme();
  const [isLogin, setIsLogin] = React.useState<boolean>(false);
  
  const primaryRed = theme.palette.error.main;

  return (
    <Box 
      className="auth-page"
      sx={{ 
        minHeight: "100vh",
        background: `linear-gradient(135deg, ${theme.palette.background.default} 0%, ${alpha(primaryRed, 0.05)} 100%)`,
        py: 4
      }}
    >
      <Container maxWidth="lg">
        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: "90vh" }}>
          <Grid item xs={12} md={10} lg={9}>
            <Paper 
              elevation={4} 
              sx={{ 
                borderRadius: "16px", 
                overflow: "hidden",
                boxShadow: "0 8px 32px rgba(0,0,0,0.1)"
              }}
            >
              <Grid container>
                {/* Left Panel */}
                <Grid item xs={12} md={6} 
                  sx={{ 
                    background: `linear-gradient(145deg, rgba(176,23,31,0.85) 0%, rgba(64,0,27,0.95) 100%)`,
                    position: "relative",
                    p: 4,
                    display: { xs: "none", md: "block" }
                  }}
                >
                  <Stack spacing={4} alignItems="center">
                    <Typography 
                      variant="h4" 
                      sx={{ 
                        fontWeight: 700, 
                        color: "white",
                        mb: 1,
                        textAlign: "center"
                      }}
                    >
                      {isLogin ? "Welcome Back" : "Join Us Today"}
                    </Typography>
                    
                    <Typography 
                      variant="subtitle1" 
                      sx={{ 
                        color: "rgba(255,255,255,0.9)", 
                        fontWeight: 400,
                        textAlign: "center",
                        mb: 2,
                        fontSize: "0.95rem"
                      }}
                    >
                      Connect with customers, manage inventory, and grow your auto parts business with our all-in-one platform.
                    </Typography>
                    
                    <Lottie
                      options={{
                        animationData: animationData,
                        loop: true,
                        autoplay: true,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        },
                      }}
                      width={350}
                      height={220}
                      style={{ marginTop: 35 }}
                    />
                    
                    {/* Social Media icons positioned at bottom */}
                    <Stack 
                      direction="row" 
                      spacing={2} 
                      sx={{ 
                        position: "absolute",
                        bottom: 25,
                        left: 0,
                        right: 0,
                        justifyContent: "center"
                      }}
                    >
                      {[
                        { Icon: SocialMediaXLogo, url: "https://twitter.com/VENUSTUSY115710" },
                        { Icon: SocialMediaFacebookLogo, url: "https://www.facebook.com/people/Venustusy/61551793565372/" },
                        { Icon: SocialMediaInstagramLogo, url: "https://www.instagram.com/venustusy.t/" },
                        { Icon: SocialMediaTiktokLogo, url: "https://www.tiktok.com/@venustusy?lang=ar" }
                      ].map((social, index) => (
                        <Box
                          key={index}
                          sx={{
                            borderRadius: "50%",
                            width: "36px",
                            height: "36px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "rgba(255,255,255,0.15)",
                            transition: "all 0.2s ease-in-out",
                            "& svg": { color: "white", filter: "brightness(0) invert(1)" },
                            "&:hover": {
                              transform: "scale(1.1)",
                              boxShadow: "0 4px 8px rgba(0,0,0,0.3)",
                              backgroundColor: "rgba(255,255,255,0.25)"
                            }
                          }}
                          className="social-media-icon"
                          onClick={() => window.open(social.url, "_blank")}
                        >
                          <social.Icon containerHeight={20} containerWidth={20} />
                        </Box>
                      ))}
                    </Stack>
                  </Stack>
                </Grid>
                
                {/* Right Panel */}
                <Grid item xs={12} md={6} sx={{ p: 4, position: "relative" }}>
                  <Stack spacing={4} alignItems="center" sx={{ width: "100%" }}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                      <Box 
                        component="img"
                        src={logo}
                        alt="Scrap Logo"
                        sx={{
                          height: 48,
                          width: 48
                        }}
                      />
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: 700,
                          letterSpacing: "1.5px",
                          ml: { xs: 1, md: 1 }
                        }}
                      >
                        <span style={{ color: primaryRed }}>S</span>crap
                      </Typography>
                    </Box>
                    
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        color: theme.palette.text.secondary,
                        mb: 4,
                        textAlign: "center"
                      }}
                    >
                      {isLogin 
                        ? "Sign in to your account to manage your auto parts business" 
                        : "Register your business and start connecting with customers"}
                    </Typography>
                    
                    <Box sx={{ width: "100%", maxWidth: "400px" }}>
                      {isLogin ? (
                        <LoginForm setIsLogin={setIsLogin} />
                      ) : (
                        <RegisterForm setIsLogin={setIsLogin} />
                      )}
                    </Box>
                    
                    <Box sx={{ mt: 2 }}>
                      <Button href="/landing" style={{ textDecoration: "none", color: primaryRed }}>
                        ← Back to Home
                      </Button>
                    </Box>
                    
                    <Box sx={{ position: "absolute", right: 16, bottom: 16 }}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <img
                          alt="Venustusy Logo"
                          src={venLogo}
                          height={32}
                          width={32}
                        />
                        <Typography
                          sx={{
                            fontWeight: 500,
                          }}
                        >
                          <em>Ven</em>
                          <span style={{ fontWeight: 700, color: "#0C1339" }}>
                            Auth
                          </span>
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
            
            <Typography 
              sx={{ 
                fontSize: 12, 
                fontWeight: 500, 
                color: theme.palette.text.secondary,
                textAlign: "center",
                mt: 3
              }}
            >
              Copyright © 2024 - VENESTUSY W.L.L
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
