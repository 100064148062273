import { PartTickit } from "./part-ticket.store";

export enum PartTicketStatus {
    OPEN = "open",
    CLOSE = "close",
    IN_DESCTION = "in_desction",
}
const demoPartTickets: PartTickit[] = [
    // OPEN Tickets (3)
    {
        _id: "PT001",
        user_id: "USR123",
        car_model: "Civic",
        car_year: "2018",
        car_make: "Honda",
        car_name: "Civic 2018 Honda",
        part_name: "Front Bumper",
        status: PartTicketStatus.OPEN,
        created_at: Date.now() - 86400000 * 2, // 2 days ago
        description: "Looking for an OEM front bumper for my Honda Civic. Preferably in silver color with no major damage.",
        images: [
            {
                image_id: "IMG001",
                image_url: "https://example.com/images/hondabumper1.jpg",
                expiry_date: Date.now() + 86400000 * 30, // 30 days from now
            },
            {
                image_id: "IMG002",
                image_url: "https://example.com/images/hondabumper2.jpg",
                expiry_date: Date.now() + 86400000 * 30,
            }
        ]
    },
    {
        _id: "PT002",
        user_id: "USR456",
        car_model: "Camry",
        car_year: "2020",
        car_make: "Toyota",
        car_name: "Camry 2020 Toyota",
        part_name: "Side Mirror Driver Side",
        status: PartTicketStatus.OPEN,
        created_at: Date.now() - 86400000 * 5, // 5 days ago
        description: "Need a replacement side mirror for driver side. Original was damaged in parking lot incident.",
        images: [
            {
                image_id: "IMG003",
                image_url: "https://example.com/images/toyotamirror.jpg",
                expiry_date: Date.now() + 86400000 * 30,
            }
        ]
    },
    {
        _id: "PT003",
        user_id: "USR789",
        car_model: "F-150",
        car_year: "2019",
        car_make: "Ford",
        car_name: "F-150 2019 Ford",
        part_name: "Headlight Assembly",
        status: PartTicketStatus.OPEN,
        created_at: Date.now() - 86400000 * 1, // 1 day ago
        description: "Need replacement headlight assembly for passenger side after minor collision.",
        images: [
            {
                image_id: "IMG004",
                image_url: "https://example.com/images/fordheadlight.jpg",
                expiry_date: Date.now() + 86400000 * 30,
                loading: false,
                error: false
            }
        ]
    },

    // IN_DESCTION Tickets (2)
    {
        _id: "PT004",
        user_id: "USR101",
        scrap_id: "SCR111",
        car_model: "Model 3",
        car_year: "2021",
        car_make: "Tesla",
        car_name: "Model 3 2021 Tesla",
        part_name: "Center Console",
        status: PartTicketStatus.IN_DESCTION,
        created_at: Date.now() - 86400000 * 7, // 7 days ago
        description: "Looking for a replacement center console for my Tesla. The current one has some scratches and I want to replace it.",
        images: [
            {
                image_id: "IMG005",
                image_url: "https://example.com/images/teslaconsole.jpg",
                expiry_date: Date.now() + 86400000 * 15,
            }
        ],
        link: "https://example.com/part/PT004",
        suggested_prices: [
            {
                scrap_id: "SCR111",
                scrap_wa_link: "https://wa.me/5566778899",
                price: 350.00,
                created_at: Date.now() - 86400000 * 3
            },
            {
                scrap_id: "SCR222",
                scrap_wa_link: "https://wa.me/9988776655",
                price: 375.00,
                created_at: Date.now() - 86400000 * 2
            }
        ]
    },
    {
        _id: "PT005",
        user_id: "USR202",
        scrap_id: "SCR333",
        car_model: "Wrangler",
        car_year: "2022",
        car_make: "Jeep",
        car_name: "Wrangler 2022 Jeep",
        part_name: "Soft Top",
        status: PartTicketStatus.IN_DESCTION,
        created_at: Date.now() - 86400000 * 4, // 4 days ago
        description: "Looking for a used soft top for my Jeep Wrangler in good condition. Black color preferred.",
        images: [
            {
                image_id: "IMG006",
                image_url: "https://example.com/images/jeepsofttop.jpg",
                expiry_date: Date.now() + 86400000 * 30,
                loading: false,
                error: false
            }
        ],
        link: "https://example.com/part/PT005",
        suggested_prices: [
            {
                scrap_id: "SCR333",
                scrap_wa_link: "https://wa.me/1231231234",
                price: 550.00,
                created_at: Date.now() - 86400000 * 1.5 // 1.5 days ago
            },
            {
                scrap_id: "SCR444",
                scrap_wa_link: "https://wa.me/4564564567",
                price: 495.00,
                created_at: Date.now() - 86400000 * 1 // 1 day ago
            }
        ]
    },

    // CLOSE Ticket (1)
    {
        _id: "PT006",
        user_id: "USR303",
        scrap_id: "SCR555",
        car_model: "Altima",
        car_year: "2017",
        car_make: "Nissan",
        car_name: "Altima 2017 Nissan",
        part_name: "Rear Bumper",
        status: PartTicketStatus.CLOSE,
        created_at: Date.now() - 86400000 * 14, // 14 days ago
        description: "Needed a replacement rear bumper after being rear-ended. Preferably in factory dark blue.",
        images: [
            {
                image_id: "IMG007",
                image_url: "https://example.com/images/nissanbumper.jpg",
                expiry_date: Date.now() + 86400000 * 5,
            }
        ],
        link: "https://example.com/part/PT006",
        suggested_prices: [
            {
                scrap_id: "SCR555",
                scrap_wa_link: "https://wa.me/7897897890",
                price: 280.00,
                created_at: Date.now() - 86400000 * 10
            },
            {
                scrap_id: "SCR666",
                scrap_wa_link: "https://wa.me/3213214321",
                price: 265.00,
                created_at: Date.now() - 86400000 * 9
            },
            {
                scrap_id: "SCR777",
                scrap_wa_link: "https://wa.me/6546546545",
                price: 300.00,
                created_at: Date.now() - 86400000 * 8
            }
        ]
    }
];

export default demoPartTickets;
