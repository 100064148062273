import React, { Fragment, useEffect } from "react";
import { AppMasterTable } from "../../../components/app.master.table";
import {
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  IDateFilterObj,
  IMasterColumns,
  ITableFilters,
} from "../../module.interface";
import { IESDoc } from "../../../app.interface";
import {
  FetchPermissionsList,
  GetMemberUsers,
  InternalGetAllUsers,
} from "../../../services/settings.service";
import { toast } from "../../../state/snackbar";
import { AppDrawer } from "../../../views/Drawer";
import { UsersAdvancedView } from "./views/advanced.view";
import EditUserRecord from "./views/edit.user";
import { useBehaviourSubject } from "../../../hooks/user-obsevrable";
import { user$ } from "../../../state/user";
import { UUIDGenerator } from "../../../util/uuid.generator";
import { setInitialDateRanges } from "../../../util/date-range";
import useDeepCompareEffect from "use-deep-compare-effect";
import { FullScreenModal } from "../../../components/app.full-screen.modal";
import { isPermissionValid } from "../../../util/user-permission-validator";
import { CreateEmployeeController } from "./add_user/create_employee.index";
import { SelectionSearch } from "../../../components/app.selection_search";
import { CopiableText } from "../../../components/copiable_text";

export const ManageUsersController: React.FC = () => {
  const module_permission = "users";
  const [masterColumns] = React.useState<IMasterColumns[]>([
    {
      masterColumnName: "member_name",
      masterColumnTitle: "Company Name",
      renderType: "text",
      cellRender: (params) => (
        <CopiableText
          textToCopy={params?.member_name || "-"}
          title={"Company Name"}
        >
          <Typography
            sx={{ fontSize: 13, fontWeight: 500 }}
            color={"text.primary"}
          >
            {params?.member_name || "-"}
          </Typography>
        </CopiableText>
      ),
    },
    {
      masterColumnName: "staff_name",
      masterColumnTitle: "Employee name",
      renderType: "text",
      cellRender: (params) => (
        <CopiableText
          textToCopy={params?.staff_name || "-"}
          title={"Employee Name"}
        >
          <Typography
            sx={{ fontSize: 13, fontWeight: 500 }}
            color={"text.primary"}
          >
            {params.staff_name}
          </Typography>
        </CopiableText>
      ),
    },
    {
      masterColumnName: "staff_email",
      masterColumnTitle: "Employee Email",
      renderType: "text",
      cellRender: (params) => (
        <CopiableText
          textToCopy={params?.staff_email || "-"}
          title={"Employee Email"}
        >
          <Typography
            sx={{ fontSize: 13, fontWeight: 500 }}
            color={"text.primary"}
          >
            {params.staff_email}
          </Typography>
        </CopiableText>
      ),
    },

    {
      masterColumnName: "role_name",
      masterColumnTitle: "Employee Role",
      renderType: "text",
      cellRender: (params) => (
        <Typography
          sx={{ fontSize: 13, fontWeight: 500 }}
          color={"text.primary"}
        >
          {params.role_name}
        </Typography>
      ),
    },
    {
      masterColumnName: "created_at",
      masterColumnTitle: "Created On",
      renderType: "date_time",
    },
    {
      masterColumnName: "_id",
      masterColumnTitle: "",
      renderType: "text",
      cellRender: (params) => {
        return (
          <Box>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              onClick={() => {
                setSelectedUser(params);
              }}
            >
              Edit
            </Button>
          </Box>
        );
      },
    },
  ]);

  const [selectedSearchField, setSelectedSearchField] = React.useState<{
    fieldName: string;
    title: string;
    icon: string;
    hideOptions?: boolean;
  }>({
    fieldName: "member_name",
    icon: "corporate_fare",
    title: "Company Name",
    hideOptions: false,
  });

  const [totalDocs] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [pageIndex, setPageIndex] = React.useState<number>(0);
  const [sortOrder, setSortOrder] = React.useState<"desc" | "asc">("desc");
  const [sortField, setSortField] = React.useState<string>("created_at");

  const [docs, setDocs] = React.useState<IESDoc[]>([]);
  const [checkedDocs, setCheckedDocs] = React.useState<IESDoc[]>([]);
  const [selectedDoc, setSelectedDoc] = React.useState<IESDoc>();
  const [selectedUser, setSelectedUser] = React.useState<IESDoc>();
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [filterList] = React.useState<ITableFilters[]>([]);
  const [searchVal, setSearchVal] = React.useState<string>("");

  const [updateId, setUpdateId] = React.useState<string>("");
  const [permissionsStructureList, setPermissionsStructureList] =
    React.useState<string[]>([]);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [addEmployeeModal, setAddEmployeeModal] =
    React.useState<boolean>(false);
  const user = useBehaviourSubject(user$);

  const fetchData = () => {
    if (user) {
      setLoading(true);
      InternalGetAllUsers(
        user.member_id,
        pageIndex,
        pageSize,
        searchQuery,
        undefined,
        undefined,
        undefined,
        sortField,
        sortOrder,
        filterList
      )
        .then((res) => {
          setDocs(res.data);
        })
        .catch(() => {
          toast(
            "Error happened while getting the users data, please try again later.",
            "error"
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const fetchUserPermissionsData = () => {
    FetchPermissionsList(1000)
      .then((permissionsResponse) => {
        const initialData: IESDoc[] = permissionsResponse.data;
        setPermissionsStructureList(
          initialData.map((v) => {
            return v.name;
          })
        );
      })
      .catch(() => {
        toast("Error happened while fetching permissions", "error");
      });
  };
  useEffect(fetchUserPermissionsData, []);
  useDeepCompareEffect(fetchData, [
    updateId,
    searchQuery,
    pageSize,
    pageIndex,
    sortOrder,
    sortField,
    filterList,
    user,
  ]);
  const onCloseModel = () => {
    setSelectedDoc(undefined);
    setSelectedUser(undefined);
  };

  return (
    <Fragment>
      <Box className="header-container" sx={{ mb: 2 }}>
        <Stack spacing={2} sx={{ mt: 4 }}>
          <Grid container justifyContent={"center"}>
            <Grid item>
              <Typography
                color={"primary"}
                sx={{ fontSize: 25, fontWeight: 600, textAlign: "center" }}
              >
                Users Manager
              </Typography>
              <Typography
                color="text.secondary"
                sx={{ fontSize: 14, textAlign: "center", paddingTop: 0.5 }}
              >
                View and manage your active employees.
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent={"center"}>
            <Grid item xl={5} lg={5} md={9} sm={10} xs={12} sx={{ mb: 2 }}>
              <SelectionSearch
                rounded
                dataSource="ds_staff"
                availableSearchFields={[
                  {
                    fieldName: "member_name",
                    icon: "corporate_fare",
                    title: "Company Name",
                    hideOptions: false,
                  },
                  {
                    fieldName: "staff_name",
                    icon: "person",
                    title: "Employee Name",
                    hideOptions: false,
                  },
                  {
                    fieldName: "staff_email",
                    icon: "contact_mail",
                    title: "Employee Email",
                    hideOptions: false,
                  },
                ]}
                setSearchQuery={setSearchQuery}
                selectedSearchField={selectedSearchField}
                setSelectedSearchField={setSelectedSearchField}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>

      <Box className="body-container">
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {/* table */}
            <Paper sx={{ paddingTop: 1, mt: 1 }}>
              <Grid
                container
                justifyContent={"flex-end"}
                spacing={1}
                sx={{ paddingRight: 2 }}
              >
                <Grid item alignSelf={"center"}>
                  <Tooltip title="Refresh">
                    <IconButton
                      onClick={() => {
                        setUpdateId(UUIDGenerator());
                      }}
                    >
                      <Icon color="primary" style={{ fontSize: "22px" }}>
                        refresh
                      </Icon>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item alignSelf={"center"}>
                  <Tooltip
                    title={
                      !isPermissionValid(`${module_permission}.edit`)
                        ? "You dont have permission to update records, contact your system admin"
                        : "Add New Employee"
                    }
                  >
                    <span>
                      <Button
                        onClick={() => {
                          setAddEmployeeModal(true);
                        }}
                        disabled={
                          !isPermissionValid(`${module_permission}.edit`)
                        }
                        variant="contained"
                        color="primary"
                      >
                        <Typography
                          sx={{ fontSize: 13, textTransform: "none" }}
                        >
                          Add Employee
                        </Typography>
                      </Button>
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 1 }} />

              <AppMasterTable
                loading={loading}
                dataSource={"users"}
                name={"users"}
                title={"users"}
                colDefs={masterColumns}
                docs={docs}
                totalDocs={totalDocs}
                pageIndex={pageIndex}
                pageSize={pageSize}
                sortField={sortField}
                sortOrder={sortOrder}
                onChangeSortField={setSortField}
                onChangeSortOrder={setSortOrder}
                onChangePageIndex={setPageIndex}
                onChangePageSize={setPageSize}
                onChangeSelectedDoc={setSelectedDoc}
                checkedDocs={checkedDocs}
                enablePagination={true}
                enableCheckBox={true}
                enableSelection={true}
                enableBrandColum={false}
                onChangeCheckedDocs={setCheckedDocs}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>

      {selectedDoc ? (
        <AppDrawer
          anchor={"right"}
          open={true}
          title={selectedDoc.staff_name}
          subTitle={selectedDoc.staff_email}
          onClose={onCloseModel}
        >
          <UsersAdvancedView doc={selectedDoc} />
        </AppDrawer>
      ) : null}

      {selectedUser ? (
        <AppDrawer
          anchor={"right"}
          open={true}
          title={selectedUser.staff_name}
          subTitle={selectedUser.staff_email}
          onClose={onCloseModel}
        >
          <EditUserRecord
            doc={selectedUser}
            setUpdateID={setUpdateId}
            onCloseModel={onCloseModel}
            permissionsStructure={permissionsStructureList}
          />
        </AppDrawer>
      ) : null}

      {addEmployeeModal && isPermissionValid("users.add") ? (
        <FullScreenModal
          isOpen={addEmployeeModal}
          onClose={() => {
            setAddEmployeeModal(false);
          }}
          width="lg"
          title="Add Employee"
          subTitle="Add New Employee Account"
          content={
            <CreateEmployeeController
              onClose={() => {
                setAddEmployeeModal(false);
                setUpdateId(UUIDGenerator());
              }}
            />
          }
        />
      ) : null}
    </Fragment>
  );
};
