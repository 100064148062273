import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { doc, updateDoc, collection, getDocs, writeBatch, onSnapshot, getDoc, query, where } from "firebase/firestore";
import { db } from "@/firebase/firebase";
import { IUserRecord } from "@/state/user";
import { UpdateExpiredImages } from "@/services/part-ticket.service";
import { user$ } from "@/state/user";
import demoPartTickets from "./data";

// TODO: rename open to active, remove in_desction
export enum PartTicketStatus {
    OPEN = "open",
    CLOSE = "close",
    IN_DESCTION = "in_desction",
}
export type PartTickitPriceSuggestion = {
    scrap_id: string;
    scrap_wa_link: string;
    price: number;
    created_at: number
}

// Sample test ticket with full data
export const TEST_TICKET: PartTickit = {
    _id: "test-ticket-001",
    user_id: "user-001",
    scrap_id: "scrap-001",
    car_model: "3 Series",
    car_year: "2002",
    car_make: "BMW",
    car_name: "BMW 3 Series (2002)",
    part_name: "Front Bumper M Sport",
    status: PartTicketStatus.OPEN,
    created_at: Date.now() - 3600000, // 1 hour ago
    description: "Looking for an original M Sport front bumper for my E46. Must be in good condition with minimal scratches. Preferably in Titanium Silver (Code: 354). The bumper should include fog light housings and grilles. Manufacturing date between 2002-2005 preferred.",
    images: [
        {
            image_id: "test-image-001",
            image_url: "https://images.unsplash.com/photo-1617469767053-d3b523a0b982?q=80&w=3540&auto=format&fit=crop",
            expiry_date: Date.now() + 7 * 24 * 60 * 60 * 1000, // 7 days from now
            loading: false,
            error: false
        },
        {
            image_id: "test-image-002",
            image_url: "https://images.unsplash.com/photo-1617469767053-d3b523a0b982?q=80&w=3540&auto=format&fit=crop",
            expiry_date: Date.now() + 7 * 24 * 60 * 60 * 1000, // 7 days from now
            loading: false,
            error: false
        }
    ],
    suggested_prices: [
        {
            scrap_id: "scrap-001",
            scrap_wa_link: "https://wa.me/97336123469?text=Hello,%20I%20am%20interested%20in%20Front%20Bumper%20M%20Sport%20for%20BMW%203%20Series%20(2002)",
            price: 150.00,
            created_at: Date.now() - 7200000 // 2 hours ago
        },
        {
            scrap_id: "scrap-002",
            scrap_wa_link: "https://wa.me/97336123470?text=Hello,%20I%20am%20interested%20in%20Front%20Bumper%20M%20Sport%20for%20BMW%203%20Series%20(2002)",
            price: 180.00,
            created_at: Date.now() - 3600000 // 1 hour ago
        },
        {
            scrap_id: "scrap-003",
            scrap_wa_link: "https://wa.me/97336123471?text=Hello,%20I%20am%20interested%20in%20Front%20Bumper%20M%20Sport%20for%20BMW%203%20Series%20(2002)",
            price: 165.00,
            created_at: Date.now() - 1800000 // 30 minutes ago
        }
    ]
};

// Helper function to check if an image is expired
const isImageExpired = (image: { expiry_date: number }) => {
    return image.expiry_date < Date.now();
};

// Helper function to collect expired images from a single ticket
const collectExpiredImages = (ticket: PartTickit): { ticket_id: string; image_ids: string[] } | null => {
    if (!ticket.images) return null;

    const expiredImages = ticket.images.filter(isImageExpired);
    if (expiredImages.length === 0) return null;

    return {
        ticket_id: ticket._id,
        image_ids: expiredImages.map(img => img.image_id)
    };
};

// Function to request new image URLs from backend for a single ticket
const requestNewImageUrls = async (expiredImageData: { ticket_id: string; image_ids: string[] }) => {
    try {
        await UpdateExpiredImages(expiredImageData);
    } catch (error) {
        console.error('Failed to request new image URLs:', error);
    }
};

export type PartTickit = {
    _id: string;
    user_id: string;
    scrap_id?: string;
    car_model: string;
    car_year: string;
    car_make: string;
    car_name: string; // INFO: concatenate car_model, car_year, car_make
    part_name: string;
    status: string;
    created_at?: number;
    description?: string;
    image_base64?: string[];
    images?: {
        image_id: string;
        image_url: string;
        expiry_date: number;
        loading?: boolean;
        error?: boolean;
    }[];
    link?: string;
    suggested_prices?: PartTickitPriceSuggestion[];
};

type IPartTicketStore = {
    loading: boolean;
    data: PartTickit[];
    resMeta: any;
    isSubmitting: boolean;
    isDialogOpen: boolean;
    whatsappNumber: string;
    showWhatsappInput: boolean;

    selectedPartTicket?: PartTickit;
    userSuggestion?: PartTickitPriceSuggestion;
    otherSuggestions?: PartTickitPriceSuggestion[];

    // Add new state for unique values
    uniqueCarMakes: { label: string; value: string; }[];
    uniqueCarModels: { label: string; value: string; }[];
    uniqueCarYears: { label: string; value: string; }[];
    fetchUniqueValues: () => Promise<void>;

    setSelectedPartTicket: (item?: PartTickit) => void;
    setDialogOpen: (open: boolean) => void;
    setWhatsappNumber: (number: string) => void;
    setShowWhatsappInput: (show: boolean) => void;
    getSavedWhatsappNumber: () => string;
    saveWhatsappNumber: (number: string) => void;
    validateWhatsappNumber: (number: string) => boolean;
    getActiveWhatsappNumber: (memberPhone?: string) => string | undefined;
    updateSuggestions: (userId: string) => void;
    deleteSuggestion: (userId: string) => Promise<void>;

    availablePartTickets: PartTickit[];
    subscribeToAvailableTickets: () => () => void;
    pendingPartTickets: PartTickit[];
    subscribeToPendingTickets: () => () => void;
    soldPartTickets: PartTickit[];
    subscribeToSoldTickets: () => () => void;

    fetchData: () => Promise<void>;
    subscribeToUpdates: () => () => void;
    offer: (user: IUserRecord, price: number, membership_phone?: string) => Promise<void>;
    addDemo: () => void;
};

// Helper function to extract and sort unique values
const extractUniqueValues = (tickets: PartTickit[], field: keyof PartTickit) => {
    return Array.from(new Set(tickets.map(ticket => ticket[field] as string)))
        .filter(Boolean)
        .sort()
        .map(value => ({
            label: value,
            value: value
        }));
};

// Helper function to update unique values in store
const updateUniqueValues = (tickets: PartTickit[], set: any) => {
    set({
        uniqueCarMakes: extractUniqueValues(tickets, 'car_make'),
        uniqueCarModels: extractUniqueValues(tickets, 'car_model'),
        uniqueCarYears: extractUniqueValues(tickets, 'car_year').sort((a, b) => b.value.localeCompare(a.value))
    });
};

export const usePartTicket = create<IPartTicketStore>()(
    devtools((set, get) => ({
        data: [TEST_TICKET],
        loading: false,
        resMeta: null,
        isSubmitting: false,
        isDialogOpen: false,
        whatsappNumber: localStorage.getItem('savedWhatsappNumber') || '',
        showWhatsappInput: false,
        userSuggestion: undefined,
        otherSuggestions: undefined,
        uniqueCarMakes: [],
        uniqueCarModels: [],
        uniqueCarYears: [],

        addDemo: async () => {
            try {
                const partTicketsCollection = collection(db, 'part-ticket');
                const batch = writeBatch(db);
                const createdIds: string[] = [];

                // Add each demo ticket to the batch
                for (const ticket of demoPartTickets) {
                    // Create a new doc reference without specifying an ID
                    const docRef = doc(partTicketsCollection);
                    // Use the ticket's _id as the document ID
                    batch.set(docRef, {
                        ...ticket,
                        _id: ticket._id, // Ensure the _id is included
                        created_at: ticket.created_at || Date.now() // Use provided or current timestamp
                    });
                    createdIds.push(ticket._id);
                }

                // Commit the batch
                await batch.commit();
                console.log(`Successfully added ${demoPartTickets.length} demo tickets to Firestore`);
                return createdIds;
            } catch (error) {
                console.error('Error adding demo tickets to Firestore:', error);
                throw error;
            }
        },

        selectedPartTicket: undefined,
        setSelectedPartTicket: async (item) => {
            // Check for expired images when selecting a ticket
            if (item) {
                const expiredImageData = collectExpiredImages(item);
                if (expiredImageData) {
                    await requestNewImageUrls(expiredImageData);
                }
            }

            set({ selectedPartTicket: item });
            if (!item) {
                set({ isDialogOpen: false });
            }
        },

        updateSuggestions: (userId: string) => {
            const state = get();
            if (!state.selectedPartTicket) return;

            const userSuggestion = state.selectedPartTicket.suggested_prices?.find(
                suggestion => suggestion.scrap_id === userId
            );

            const otherSuggestions = state.selectedPartTicket.suggested_prices?.filter(
                suggestion => suggestion.scrap_id !== userId
            );

            set({ userSuggestion, otherSuggestions });
        },

        setDialogOpen: (open) => {
            if (!open) {
                // Only reset WhatsApp input state when closing dialog
                set({
                    isDialogOpen: false,
                    showWhatsappInput: false
                });
            } else {
                const state = get();
                const user = user$.getValue();
                if (user && state.selectedPartTicket) {
                    state.updateSuggestions(user.member_id);
                }
                set({ isDialogOpen: true });
            }
        },

        setWhatsappNumber: (number) => set({ whatsappNumber: number }),

        setShowWhatsappInput: (show) => set({ showWhatsappInput: show }),

        getSavedWhatsappNumber: () => {
            return localStorage.getItem('savedWhatsappNumber') || '';
        },

        saveWhatsappNumber: (number) => {
            localStorage.setItem('savedWhatsappNumber', number);
            set({ whatsappNumber: number });
        },

        validateWhatsappNumber: (number) => {
            const cleanNumber = number.replace(/\D/g, '');
            return cleanNumber.length >= 8;
        },

        getActiveWhatsappNumber: (memberPhone) => {
            const state = get();
            // Priority: 1. Member phone 2. Saved number 3. Current input
            return memberPhone || state.getSavedWhatsappNumber() || state.whatsappNumber;
        },

        fetchData: async () => {
            set({ loading: true });
            try {
                const querySnapshot = await getDocs(collection(db, "part-ticket"));
                const partTickets = querySnapshot.docs.map((doc: any) => ({
                    _id: doc.id,
                    ...doc.data(),
                }));
                const allTickets = [TEST_TICKET, ...partTickets];
                set({ data: allTickets, loading: false });
            } catch (error) {
                console.error("Error fetching data:", error);
                set({ data: [TEST_TICKET], loading: false });
            }
        },

        subscribeToUpdates: () => {
            const unsubscribe = onSnapshot(collection(db, "part-ticket"), (snapshot) => {
                const partTickets = snapshot.docs.map((doc) => ({
                    _id: doc.id,
                    ...doc.data(),
                })) as PartTickit[];

                const allTickets: PartTickit[] = [TEST_TICKET, ...partTickets];

                // Update unique values when data changes
                updateUniqueValues(allTickets, set);

                for (const ticket of allTickets) {
                    const expiredImageData = collectExpiredImages(ticket);
                    if (expiredImageData) {
                        requestNewImageUrls(expiredImageData);
                        break;
                    }
                }
                set({
                    data: allTickets,
                });
            });
            return unsubscribe;
        },

        soldPartTickets: [],
        subscribeToSoldTickets: () => {
            const q = query(
                collection(db, "part-ticket"),
                where("status", "==", PartTicketStatus.CLOSE)
            );

            const unsubscribe = onSnapshot(q, (snapshot) => {
                const partTickets = snapshot.docs.map((doc) => ({
                    _id: doc.id,
                    ...doc.data(),
                })) as PartTickit[];

                // Include TEST_TICKET only if it matches the status
                const allTickets = TEST_TICKET.status === PartTicketStatus.CLOSE
                    ? [TEST_TICKET, ...partTickets]
                    : partTickets;

                for (const ticket of allTickets) {
                    const expiredImageData = collectExpiredImages(ticket);
                    if (expiredImageData) {
                        requestNewImageUrls(expiredImageData);
                    }
                }
                set({ soldPartTickets: allTickets });
                updateUniqueValues(allTickets, set);
            });
            return unsubscribe;
        },

        pendingPartTickets: [],
        subscribeToPendingTickets: () => {
            const q = query(
                collection(db, "part-ticket"),
                where("status", "==", PartTicketStatus.IN_DESCTION)
            );

            const unsubscribe = onSnapshot(q, (snapshot) => {
                const partTickets = snapshot.docs.map((doc) => ({
                    _id: doc.id,
                    ...doc.data(),
                })) as PartTickit[];

                // Include TEST_TICKET only if it matches the status
                const allTickets = TEST_TICKET.status === PartTicketStatus.IN_DESCTION
                    ? [TEST_TICKET, ...partTickets]
                    : partTickets;

                for (const ticket of allTickets) {
                    const expiredImageData = collectExpiredImages(ticket);
                    if (expiredImageData) {
                        requestNewImageUrls(expiredImageData);
                    }
                }
                set({ pendingPartTickets: allTickets });
                updateUniqueValues(allTickets, set);
            });
            return unsubscribe;
        },

        availablePartTickets: [],
        subscribeToAvailableTickets: () => {
            const q = query(
                collection(db, "part-ticket"),
                where("status", "==", PartTicketStatus.OPEN)
            );

            const unsubscribe = onSnapshot(q, (snapshot) => {
                const partTickets = snapshot.docs.map((doc) => ({
                    _id: doc.id,
                    ...doc.data(),
                })) as PartTickit[];

                // Include TEST_TICKET only if it matches the status
                const allTickets = TEST_TICKET.status === PartTicketStatus.OPEN
                    ? [TEST_TICKET, ...partTickets]
                    : partTickets;

                for (const ticket of allTickets) {
                    const expiredImageData = collectExpiredImages(ticket);
                    if (expiredImageData) {
                        requestNewImageUrls(expiredImageData);
                    }
                }
                set({ availablePartTickets: allTickets });
                updateUniqueValues(allTickets, set);
            });
            return unsubscribe;
        },

        offer: async (user, price, membership_phone) => {
            const { selectedPartTicket } = get()
            if (!selectedPartTicket) return

            set({ isSubmitting: true });
            try {
                const phoneNumber = user.member_phone || membership_phone || "97336123469"
                const message = `Hello, I am interested in ${selectedPartTicket.part_name} for ${selectedPartTicket.car_name}. My offered price is ${price} BD.`;
                const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

                const newSuggestion = {
                    scrap_id: user.member_id,
                    scrap_wa_link: whatsappUrl,
                    price: price,
                    created_at: Date.now()
                };

                if (selectedPartTicket._id === TEST_TICKET._id) {
                    // Handle test ticket locally
                    const currentTicket = { ...TEST_TICKET };
                    const suggestedPrices = currentTicket.suggested_prices || [];

                    // Remove existing user suggestion if any
                    const filteredPrices = suggestedPrices.filter(s => s.scrap_id !== user.member_id);

                    const updatedTicket = {
                        ...currentTicket,
                        suggested_prices: [...filteredPrices, newSuggestion]
                    };

                    set({
                        data: [updatedTicket],
                        selectedPartTicket: updatedTicket,
                        isDialogOpen: false
                    });

                    // Update suggestions after offer
                    get().updateSuggestions(user.member_id);
                } else {
                    // Handle real tickets
                    const id = selectedPartTicket._id;
                    const docRef = doc(db, "part-ticket", id);
                    const docSnap = await getDoc(docRef);
                    const currentTicket = docSnap.data() as PartTickit;
                    const suggestedPrices = currentTicket.suggested_prices || [];

                    // Remove existing user suggestion if any
                    const filteredPrices = suggestedPrices.filter(s => s.scrap_id !== user.member_id);

                    await updateDoc(docRef, {
                        suggested_prices: [...filteredPrices, newSuggestion]
                    });
                }
                set({ isDialogOpen: false });
            } catch (error) {
                console.error("Failed to add price suggestion:", error);
                throw error;
            } finally {
                set({ isSubmitting: false });
                const { data } = get()
                set({ selectedPartTicket: data.find(item => item._id === selectedPartTicket._id) })
            }
        },

        deleteSuggestion: async (userId: string) => {
            const state = get();
            const selectedPartTicket = state.selectedPartTicket;
            if (!selectedPartTicket) return;

            try {
                set({ isSubmitting: true });

                if (selectedPartTicket._id === TEST_TICKET._id) {
                    // Handle test ticket locally
                    const currentTicket = { ...TEST_TICKET };
                    const updatedPrices = currentTicket.suggested_prices?.filter(
                        s => s.scrap_id !== userId
                    ) || [];

                    const updatedTicket = {
                        ...currentTicket,
                        suggested_prices: updatedPrices
                    };

                    set({
                        data: [updatedTicket],
                        selectedPartTicket: updatedTicket
                    });

                    // Update suggestions after deletion
                    get().updateSuggestions(userId);
                } else {
                    // Handle real tickets
                    const docRef = doc(db, "part-ticket", selectedPartTicket._id);
                    const docSnap = await getDoc(docRef);
                    const currentTicket = docSnap.data() as PartTickit;

                    const updatedPrices = currentTicket.suggested_prices?.filter(
                        s => s.scrap_id !== userId
                    ) || [];

                    await updateDoc(docRef, {
                        suggested_prices: updatedPrices
                    });
                }
            } catch (error) {
                console.error("Failed to delete price suggestion:", error);
                throw error;
            } finally {
                set({ isSubmitting: false });
                const { data } = get()
                set({ selectedPartTicket: data.find(item => item._id === selectedPartTicket._id) })
            }
        }
    }))
);

